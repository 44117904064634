import { faCameraSlash } from "@fortawesome/pro-duotone-svg-icons";
import { faTrash } from "@fortawesome/pro-regular-svg-icons";
import { faStore } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  calculateConfigTotal,
  configToDisplayString,
  editConfigAmount,
  priceFormatter,
} from "@kanpla/system";
import {
  BasketItemConfig,
  CombinedOfferItem,
  OrderConfig,
  OrderInfo as OrderInfoType,
  OrderOrder,
  OrderOrderProduct,
  Plugins,
} from "@kanpla/types";
import {
  Badge,
  Image,
  InputAmount,
  TimeInputDisplay,
  TimeSelect,
  TooltipHiddenPrices,
} from "@kanpla/ui";
import { Button, FormInstance, Tooltip, TooltipProps } from "antd";
import classnames from "classnames";
import { isEmpty } from "lodash";
import { FC, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useContainer } from "unstated-next";
import { OrderingContext } from "../../context";
import { WithEditType } from "./BasketList";
import { EditModeType, isEditModeEnabled } from "./BasketListTypes";
import { OrderInfo } from "./OrderInfo";

interface BasketListItemProps {
  /** A configuration item from the basket */
  item: BasketItemConfig;
  /** If the item is editable */
  editMode?: boolean;
  /** What can be edited */
  editType?: EditModeType;
  /** The basket */
  basket?: OrderOrder;
  /** Setter function for the basket */
  setBasket?: (order: OrderOrder) => void;
  /** Is the item the last one (avoid extra lines on the last element) */
  isLast?: boolean;
  /** Adds a space to the UI */
  hasGap?: boolean;
  /** If the item can be deleted */
  hideDeleteButton?: boolean;
  /** The type of the item, depends on which module the item was added */
  orderInfo?: OrderInfoType;
  form?: FormInstance<any>;
  showOrderInfo?: boolean;
  type?: WithEditType["type"];
  /** Hides the product picture */
  hidePicture?: boolean;
  /** Change the placement of the tooltip (used for informing the user on hidden prices) */
  tooltipPlacement?: TooltipProps["placement"];
  /** Displays the amount of the product as a string, useful when the picture is hidden */
  displayStringAmount?: boolean;
}

export const BasketListItem: FC<BasketListItemProps> = ({
  editMode = false,
  editType = "both",
  item: { productId, config, price, name, photo },
  basket: basketFromProps,
  setBasket: setBasketFromProps,
  isLast,
  hasGap,
  hideDeleteButton = false,
  orderInfo,
  form,
  showOrderInfo,
  type,
  hidePicture = false,
  tooltipPlacement = "top",
  displayStringAmount = false,
}) => {
  const { i18n, t } = useTranslation("mealplan2");

  const hasLanguage = !isEmpty(i18n);

  const {
    offer,
    module,
    activePlugins,
    date,
    basket: basketFromContext,
    setBasket: setBasketFromContext,
  } = useContainer(OrderingContext);
  const items = offer?.items || [];
  const dateSeconds = date.seconds;

  const basket = basketFromProps || basketFromContext;
  const setBasket = setBasketFromProps || setBasketFromContext;

  const isRequiredProduct =
    productId === module?.plugins?.requiredProduct?.productId;
  const isAdhoc = productId.startsWith("ad-hoc-");
  const isEditable = editMode && !isAdhoc && !isRequiredProduct;
  const plugins = module?.plugins || ({} as Plugins.Array);

  // Original amount is used for pay-per-order
  const [originalAmount] = useState(config.amount);

  const amountEditable = isEditModeEnabled(editType, "amount");
  const timeEditable = isEditModeEnabled(editType, "time");

  const product: CombinedOfferItem = items?.find(
    (p: CombinedOfferItem) => p.productId === productId
  );
  const { photo: photoFromOffer, name: productName } =
    product || ({} as CombinedOfferItem);

  const totalConfigPrice = calculateConfigTotal(config);

  const { hidePrices } = module?.config || {};

  const configText = useMemo(() => configToDisplayString(config), [config]);

  const isSignupOfferProduct =
    activePlugins["signupOffer"] &&
    plugins?.signupOffer?.productId === productId;

  const isPayPerOrder = activePlugins?.["payPerOrder"];

  let stock = product?.dates?.[dateSeconds]?.stock;
  if (isSignupOfferProduct && (stock || 0) > 1) stock = 1;

  const changeAmount = useCallback(
    (value: number) => {
      setBasket(
        editConfigAmount({
          order: basket,
          productId,
          choice: config.options,
          amountChange: value,
          replaceAmount: true,
        })
      );
    },
    [basket, setBasket, productId, config.options]
  );

  const setTime = useCallback(
    (newTimeValue: number) => {
      if (newTimeValue && newTimeValue === config.deliveryTime) return;

      const targetProduct: OrderOrderProduct = basket[productId];
      const productConfigs = targetProduct?.config?.map((c: OrderConfig) => {
        if (c.uid !== config.uid) return c;

        return {
          ...config,
          deliveryTime: newTimeValue,
        };
      });

      setBasket({
        ...basket,
        [productId]: {
          ...targetProduct,
          config: productConfigs,
        },
      });
    },
    [config, basket, productId, setBasket]
  );

  return (
    <div className={classnames({ "mb-3": editMode && !isLast })}>
      <div className="text-text-primary">
        <div
          className={`${
            hasGap ? "gap-x-4 md:gap-x-16" : ""
          } flex justify-between items-center py-2 w-full h-full`}
        >
          <div className="flex items-center flex-shrink overflow-hidden pt-3">
            {!hidePicture && (
              <Badge count={config.amount} hidden={isEditable}>
                <div
                  className="h-16 w-16 rounded-lg bg-background-secondary flex-shrink-0 relative overflow-hidden border border-divider-main"
                  style={{
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                >
                  {photo || photoFromOffer ? (
                    <Image
                      src={photoFromOffer || photo}
                      size={{ w: 64, h: 64 }}
                      alt={productName || name}
                      className="absolute inset-0 min-w-full min-h-full h-full w-full object-cover object-center shadow-inner"
                    />
                  ) : (
                    <div
                      className="absolute w-full h-full bg-primary-main flex items-center justify-center"
                      style={{ backgroundColor: product?.color }}
                    >
                      <FontAwesomeIcon icon={faCameraSlash} />
                    </div>
                  )}
                </div>
              </Badge>
            )}
            <div
              className={classnames(
                "text-text-secondary md:w-auto overflow-hidden",
                {
                  "mx-3 md:ml-3": !hidePicture,
                }
              )}
              data-cy="product-order-name"
            >
              <p
                className="font-medium truncate whitespace-normal"
                title={productName || name}
              >
                {productName || name}
                {isAdhoc && (
                  <Tooltip title="Købt i kantinen" placement="right">
                    <span className="rounded inline-block bg-background-secondary p-1 px-2 ml-3 text-xs text-primary-dark">
                      <FontAwesomeIcon icon={faStore} />
                    </span>
                  </Tooltip>
                )}
              </p>
              {config.options && (
                <>
                  {configText && (
                    <p className="text-sm mt-1 text-text-secondary">
                      {configText}
                    </p>
                  )}
                  {config?.deliveryTime &&
                    !(
                      activePlugins["timeInput"] &&
                      timeEditable &&
                      editMode
                    ) && (
                      <div className="mt-1 text-sm text-text-secondary">
                        <TimeInputDisplay timeInput={config.deliveryTime} />
                      </div>
                    )}
                </>
              )}
            </div>
          </div>
          <div className="h-full whitespace-nowrap flex items-center pt-3 md:p-0">
            <div className="text-primary-dark flex items-center py-1 text-right">
              {displayStringAmount && (
                <p className="text-left text-text-primary font-bold text-sm mr-2">
                  {config.amount}x
                </p>
              )}
              <p className="text-right">
                {priceFormatter(
                  config.amount * (price + totalConfigPrice),
                  {
                    language: i18n.language,
                  },
                  false,
                  hidePrices,
                  {
                    wrapper: () => "-",
                    component: () => (
                      <TooltipHiddenPrices placement={tooltipPlacement} />
                    ),
                  }
                )}
              </p>
            </div>
          </div>
        </div>
      </div>
      {isEditable && (
        <>
          <div className="flex items-center justify-between">
            <div className="flex items-end">
              {amountEditable && editMode && (
                <InputAmount
                  amount={config.amount}
                  setAmount={(value: number) => changeAmount(value)}
                  minAmount={hideDeleteButton ? 0 : 1}
                  maxAmount={
                    isSignupOfferProduct
                      ? 1
                      : isPayPerOrder && type === "receipt"
                      ? originalAmount
                      : stock
                  }
                  size="small"
                  className="mr-3"
                />
              )}
              {editMode && activePlugins["timeInput"] && timeEditable && (
                <TimeSelect
                  interval={plugins.timeInput?.interval}
                  endAt={plugins.timeInput?.endAt}
                  startAt={plugins.timeInput?.startAt}
                  /** Sometimes the deliveryTime is undefined */
                  value={config.deliveryTime || plugins.timeInput?.startAt}
                  onChange={setTime}
                  defaultText={
                    hasLanguage
                      ? t("default-delivery-time")
                      : "Generel leveringstidspunkt"
                  }
                />
              )}
            </div>
            {amountEditable && !hideDeleteButton && (
              <Button
                className="ml-3"
                type="primary"
                danger
                onClick={() => changeAmount(0)}
              >
                <FontAwesomeIcon icon={faTrash} />
              </Button>
            )}
          </div>
          {showOrderInfo && isLast && (
            <OrderInfo basket={basket} orderInfo={orderInfo} form={form} />
          )}
        </>
      )}
    </div>
  );
};
