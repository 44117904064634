import React, { useContext, useEffect, useState } from "react";
import { PopupConstructor, Supplier } from "@kanpla/types";
import {
  CarouselProvider,
  Slide,
  Slider,
  CarouselContext,
} from "pure-react-carousel";
import { Screen } from "./Screen";
import { PushNotificationsScreen } from "./PushNotification.screen";
import { TrackingScreen } from "./Tracking.screen";

interface Props {
  screens: PopupConstructor.Fullscreen[];
  supplier: Supplier;
  /** Listener on the user allowing/rejecting the notification system alert */
  notificationPermissionsReceived?: boolean;
  /** Listener on the user allowing/rejecting the tracking system alert */
  trackingPermissionsReceived?: boolean;
  /** Fired on button click in "Allow Notifications" screen */
  onAllowNotifications?: () => void | Promise<void>;
  /** Fired on button click in "Allow Tracking" screen */
  onAllowTracking?: () => void | Promise<void>;
  /** Fired on the last custom screen */
  onLastCustomScreen?: () => void | Promise<void>;
  /** Fired after all the screens, either custom or system */
  onFinish: () => void | Promise<void>;
  /** Hides the permissions screens */
  hideSystemScreens?: boolean;
  /** Show system screens only */
  showSystemScreensOnly?: boolean;
  /** Displays the carousel in a desktop friendly way */
  desktopView?: boolean;
  /** Loading state for the button on the last custom screen */
  lastButtonLoading?: boolean;
  /** Hides the preview component on the tracking screen */
  hidePreviewOnTrackingScreen?: boolean;
}

export const SignupCarousel = (props: Props) => {
  const {
    screens,
    supplier,
    onFinish,
    notificationPermissionsReceived = false,
    trackingPermissionsReceived = false,
    onAllowNotifications = () => true,
    onAllowTracking = () => true,
    onLastCustomScreen = () => null,
    hideSystemScreens = false,
    showSystemScreensOnly = false,
    desktopView = false,
    lastButtonLoading = false,
    hidePreviewOnTrackingScreen = false,
  } = props;
  const carouselContext = useContext(CarouselContext);

  const screensAmount = screens.length;

  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    if (!carouselContext) return;

    function onChange() {
      setCurrentSlide(carouselContext.state.currentSlide);
    }
    onChange();
    carouselContext.subscribe(onChange);
    return () => carouselContext.unsubscribe(onChange);
  }, [carouselContext]);

  useEffect(() => {
    if (!notificationPermissionsReceived && !trackingPermissionsReceived)
      return;

    if (notificationPermissionsReceived && !trackingPermissionsReceived) {
      setCurrentSlide(screensAmount + 1);
      return;
    }

    if (trackingPermissionsReceived) {
      onFinish();
      return;
    }
  }, [notificationPermissionsReceived, trackingPermissionsReceived]);

  return (
    <CarouselProvider
      totalSlides={hideSystemScreens ? screensAmount : screensAmount + 2}
      naturalSlideWidth={1}
      naturalSlideHeight={1}
      className="w-full h-full pb-0 select-none"
      orientation="horizontal"
      lockOnWindowScroll
      touchEnabled={false}
      dragEnabled={false}
      currentSlide={currentSlide}
    >
      <Slider
        classNameTrayWrap="h-full"
        classNameTray="flex h-full transition"
        className="h-full"
        style={{
          height: "100% !important",
        }}
      >
        {!showSystemScreensOnly &&
          screens.map((popup, i) => {
            return (
              <Slide
                innerClassName="h-full p-0 m-0"
                className="h-full"
                key={`slide-${popup.id}-${i}`}
                index={i}
                style={{
                  padding: 0,
                  height: "100% !important",
                }}
              >
                <Screen
                  popup={popup}
                  supplier={supplier}
                  onLastScreen={onLastCustomScreen}
                  onFinish={onFinish}
                  lastButtonLoading={lastButtonLoading}
                  hideSystemScreens={hideSystemScreens}
                  desktopView={desktopView}
                />
              </Slide>
            );
          })}
        {/* System screens: hardcoded */}
        {!hideSystemScreens && (
          <Slide
            style={{ padding: 0 }}
            className="h-full"
            innerClassName="h-full p-0 m-0"
            key={`slide-notifications-${screensAmount}`}
            index={screensAmount}
          >
            <PushNotificationsScreen
              supplier={supplier}
              onContinue={onAllowNotifications}
            />
          </Slide>
        )}
        {!hideSystemScreens && (
          <Slide
            style={{ padding: 0 }}
            className="h-full"
            innerClassName="h-full p-0 m-0"
            key={`slide-tracking-${screensAmount + 1}`}
            index={screensAmount + 1}
          >
            <TrackingScreen
              onContinue={onAllowTracking}
              hidePreview={hidePreviewOnTrackingScreen}
            />
          </Slide>
        )}
      </Slider>
    </CarouselProvider>
  );
};
