import { OrderConfig } from "@kanpla/types";
import { sortBy } from "lodash";
import moment from "moment";

/**
 *
 * @param config The products single config to retrieve the name
 * @param showDeliveryTime Whether the string should include config-specific delivery time, if applicable
 * @returns A name, possibly with delivery time if applicable
 */
export const configToDisplayString = (
  config: Pick<OrderConfig, "options" | "deliveryTime">,
  showDeliveryTime?: boolean,
  language?: string
) => {
  if (language) moment.locale(language);
  /** Sorts all config by name, so it's always the same */
  const variantString = sortBy(Object.values(config.options || {}), "name")
    .filter((o) => o.amount > 0)
    /** Add x multiplier, if more than one is purchased */
    .map((o) => `${o.amount > 1 ? `${o.amount}x ` : ""}${o.name || ""}`)
    /** Filters out empty strings */
    .filter((o) => o !== "")
    /** Puts them together in a joint string */
    .join(", ");

  /** Returns only the name if we don't want the delivery time */
  if (!showDeliveryTime) return variantString;

  const deliveryString = config?.deliveryTime
    ? `(<span class="italic">🕑 Specifik leveringstidspunkt: ${moment
        .utc(config.deliveryTime * 1000)
        .format("LT")}</span>)`
    : "";

  /** If there is no delivery time, return an empty string */
  if (deliveryString === "") return variantString;

  return `${variantString} ${deliveryString}`;
};
