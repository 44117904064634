import {
  CombinedOfferItem,
  CustomOrderContent,
  FlexBulkStandard,
  IBaseProducts,
  ISelectProductByDayProps,
  OrderInfo,
  OrderOrder,
  Supplier,
} from "@kanpla/types";
import React, { Dispatch, SetStateAction, useMemo } from "react";
import { NoLunchButton } from "./NoLunchButton";
import { ProductItem } from "./ProductItem";
import { ProductsListView } from "./ProductsListView";

interface Props {
  categories: {
    [key: string]: CombinedOfferItem[];
  };
  isBulk: boolean;
  date: string;
  selectedProducts: IBaseProducts;
  fromStandard?: boolean;
  mobile?: boolean;
  hidePrices?: boolean;
  schoolId?: string;
  setSelectedProduct: Dispatch<SetStateAction<CombinedOfferItem>>;
  setOpenVariants: Dispatch<SetStateAction<boolean>>;
  setDayDateSeconds: Dispatch<SetStateAction<string>>;
  selectProductByDay: (props: ISelectProductByDayProps) => void;
  onPurchase?: (
    product: CombinedOfferItem,
    data: CustomOrderContent,
    date: string
  ) => Promise<void>;
  submit: (
    newOrder: OrderOrder,
    info: OrderInfo,
    dateSeconds: string
  ) => Promise<void>;
  setStandardWeek?: Dispatch<SetStateAction<FlexBulkStandard["standard"]>>;
  isPast?: boolean;
  weekProducts?: IBaseProducts;
  supplier?: Supplier;
  isHoliday?: boolean;
  isHolidayFromAdmin?: boolean;
  setVariants?: Dispatch<SetStateAction<CustomOrderContent>>;
  standardWeek?: FlexBulkStandard["standard"];
}

export const ProductsList = ({
  fromStandard = false,
  hidePrices = false,
  categories = {},
  selectedProducts,
  isBulk,
  date,
  mobile,
  schoolId,
  setSelectedProduct,
  setOpenVariants,
  setDayDateSeconds,
  selectProductByDay,
  onPurchase,
  submit,
  setStandardWeek,
  isPast,
  weekProducts,
  supplier,
  isHoliday,
  isHolidayFromAdmin,
  setVariants,
  standardWeek,
}: Props) => {
  const productIsFromStandard = weekProducts?.[date]?.["isStandard"];

  /**
   * The layout change based on the number of categories
   * and their products
   */
  const isMultipleCategories = useMemo(() => {
    return (
      Object.keys(categories || {}).length > 1 &&
      Object.entries(categories || {}).filter(
        ([_, products]) => products?.length
      ).length > 1
    );
  }, [categories]);

  const singleProducts = useMemo(
    () => Object.values(categories || {})?.[0] || [],
    [categories]
  );

  const productItemObject = useMemo(() => {
    const props = {
      supplier,
      fromStandard,
      date,
      selectedProducts,
      selectProductByDay,
      isSingleCategory: !isMultipleCategories,
      hidePrices,
      schoolId,
      setSelectedProduct,
      setOpenVariants,
      setDayDateSeconds,
      onPurchase,
      setStandardWeek,
      isBulk,
      isPast,
      isItemFromStandard: productIsFromStandard,
      isMultipleCategories,
      setVariants,
      standardWeek,
    };

    return props;
  }, [
    supplier,
    fromStandard,
    date,
    selectedProducts,
    selectProductByDay,
    hidePrices,
    schoolId,
    setSelectedProduct,
    setOpenVariants,
    setDayDateSeconds,
    onPurchase,
    setStandardWeek,
    isBulk,
    isPast,
    isMultipleCategories,
    setVariants,
    standardWeek,
    productIsFromStandard,
  ]);

  const noLunchButtonObject = useMemo(() => {
    const props = {
      date,
      selectProductByDay,
      selectedProducts,
      submit,
      setStandardWeek,
      isBulk,
      fromStandard,
      isPast,
      isHolidayFromAdmin,
      isMultipleCategories,
    };

    return props;
  }, [
    date,
    selectProductByDay,
    selectedProducts,
    submit,
    setStandardWeek,
    isBulk,
    fromStandard,
    isPast,
    isHolidayFromAdmin,
    isMultipleCategories,
  ]);

  if (isMultipleCategories) {
    return (
      <ProductsListView
        ProductItem={ProductItem}
        NoLunchButton={NoLunchButton}
        productItemProps={productItemObject}
        noLunchButtonProps={noLunchButtonObject}
        mobile={mobile}
        categories={categories}
        isBulk={isBulk}
        isHolidayFromUser={isHoliday}
        fromStandard={fromStandard}
      />
    );
  }

  return (
    <ProductsListView
      ProductItem={ProductItem}
      NoLunchButton={NoLunchButton}
      productItemProps={productItemObject}
      noLunchButtonProps={noLunchButtonObject}
      mobile={mobile}
      singleProducts={singleProducts}
      isBulk={isBulk}
      isHolidayFromUser={isHoliday}
      fromStandard={fromStandard}
    />
  );
};
