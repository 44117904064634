import { CombinedOfferItem } from "@kanpla/types";

interface Props {
  product: CombinedOfferItem;
  date: string;
  noLunch: string;
}

export const getProductName = ({ product, date, noLunch }: Props) => {
  const menuDay = Object.entries(product?.dates || {}).find(
    ([dateSeconds, _]) => dateSeconds === date
  )?.[1];

  const productInfos = {
    name: product?.name || noLunch,
    description: product?.description,
  };

  return productInfos;
};
