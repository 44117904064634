import React from "react";
import { faQuestionCircle } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip, TooltipProps } from "antd";
import { useTranslation } from "react-i18next";

interface Props {
  /** Custom color of the icon */
  color?: string;
  /** Control the placement of the tooltip */
  placement?: TooltipProps["placement"];
}

export const TooltipHiddenPrices = ({
  color = "",
  placement = "top",
}: Props) => {
  const { t } = useTranslation(["translation"]);

  return (
    <Tooltip
      title={t("translation:hidden-prices")}
      trigger={["hover", "click"]}
      placement={placement}
      arrowPointAtCenter
    >
      <FontAwesomeIcon
        icon={faQuestionCircle}
        onClick={(event) => {
          event.stopPropagation();
        }}
        className={color}
      />
    </Tooltip>
  );
};
