import { adjustRequiredProduct } from "@kanpla/system";
import {
  CombinedOfferItem,
  FlexOption,
  Module,
  OrderConfig,
  OrderOrder,
  Plugins,
} from "@kanpla/types";
import { ProductOptions } from "@kanpla/ui";
import { isEmpty, omit } from "lodash";
import React, { useEffect, useState } from "react";

interface Props {
  settings: Plugins.RequiredProduct;
  requiredProductOrder: OrderOrder;
  setRequiredProductOrder: (newValue: OrderOrder) => void;
  mealOptions: Array<FlexOption>;
  module: Module;
  order: OrderOrder;

  offerItems: CombinedOfferItem[];
}

export const RequiredProduct = ({
  settings,
  setRequiredProductOrder,
  mealOptions,
  module,
  order,
  offerItems,
}: Props) => {
  const { productId } = settings;
  const [optionChoices, setOptionChoices] = useState<OrderConfig["options"]>(
    {}
  );
  const product = offerItems.find((i) => i.productId === productId);

  const defaultOptionChoices = order?.[productId]?.config?.[0]?.options || {};
  const optionsEmpty = isEmpty(optionChoices);
  useEffect(() => {
    if (optionsEmpty) setOptionChoices(defaultOptionChoices);
  }, [optionsEmpty]);

  const orderWithoutRequiredProduct = omit(order, [productId]);
  // submit data to the order
  const submitProduct = () => {
    const newOrder = adjustRequiredProduct({
      order,
      module,
      offerItems,
      optionChoices,
    });
    setRequiredProductOrder(newOrder);
  };

  const orderTrigger = JSON.stringify(orderWithoutRequiredProduct); // Make sure that adding a required product doesn't trigger the function again
  useEffect(submitProduct, [JSON.stringify(optionChoices), orderTrigger]);
  useEffect(submitProduct, []);

  if (!product) return null;

  const options = mealOptions?.filter((option: FlexOption) =>
    product.options?.includes(option.id)
  );

  return (
    <div>
      <div className="mx-2">
        <ProductOptions
          product={{
            name: product.name,
            id: productId,
            color: product.color,
            price: product.price,
          }}
          options={options}
          value={optionChoices}
          onChange={(newValue) => {
            // update locally
            setOptionChoices(newValue);
          }}
        />
      </div>
    </div>
  );
};
