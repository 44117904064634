import { db, fetchCollection } from "@kanpla/system";
import { FlexOption, Module, School } from "@kanpla/types";

type Props = {
  school: School;
  modules: Module[];
};

export const getProductOptions = async ({ school, modules }: Props) => {
  const allProductBankIds = modules
    .map((module) => {
      const shopProductBanks =
        module.productLines?.map((p) => p.productBankId) || [];
      const sourceProductBanks = module.sourceProductBankIds || [];
      const schoolProductBanks = [school?.productBankId] || [];

      const allModuleProductBankIds = [
        ...shopProductBanks,
        ...sourceProductBanks,
        ...schoolProductBanks,
      ].filter((id) => typeof id === "string");

      return allModuleProductBankIds;
    })
    .flat();

  const uniqueProductBankIds = [...new Set(allProductBankIds)];

  const promises = uniqueProductBankIds.map(async (productBankId) => {
    return await fetchCollection<FlexOption>(
      db
        .collection("productOptions")
        .where("productBankId", "==", productBankId)
    );
  });

  const productOptions = (await Promise.all(promises)).flat();

  return productOptions || [];
};
