import { OrderInfo, Plugins } from "@kanpla/types";
import { Select } from "antd";
import { default as React, FC, useCallback, useEffect, useMemo } from "react";
import { hours, minutes } from "../elements/ReceiptItem/timeInputData";

const SEPARATOR = " : ";

const getHour = (timeInputValue: TimeInputType): number =>
  Math.floor(timeInputValue / 3600);

const getMinutes = (timeInputValue: TimeInputType): number =>
  Math.floor((timeInputValue % 3600) / 60) || 0;

const getTimeOption = (hours: number, minutes: number): string => {
  return [
    hours.toString().padStart(2, "0"),
    SEPARATOR,
    minutes.toString().padStart(2, "0"),
  ].join("");
};

const getAvailableOptions = (
  startAt: number,
  endAt: number,
  interval: number
): string[] => {
  if (
    typeof startAt !== "number" ||
    typeof endAt !== "number" ||
    typeof interval !== "number"
  )
    return [];
  const intervalInMinutes = interval * 60;

  let allValues = [];
  let current = startAt;
  while (current <= endAt && allValues.length < 1440) {
    allValues.push(current);
    current = current + intervalInMinutes;
  }

  const options = allValues.map((value) => {
    const hours = getHour(value);
    const minutes = getMinutes(value);
    return getTimeOption(hours, minutes);
  });

  return options;
};

type TimeInputType = OrderInfo["timeInput"];

export interface TimeSelectProps {
  interval: Plugins.MinuteInterval;
  endAt: number;
  startAt: number;
  value: TimeInputType;
  onChange: (newValue: TimeInputType) => void;
  /** Text that appears if no text is selected */
  defaultText?: string;
}

export const TimeSelect: FC<TimeSelectProps> = ({
  onChange,
  value,
  interval = 30,
  startAt = 27000,
  endAt = 57600,
  defaultText = "Vælg tid",
}) => {
  const selectedTimeOption = useMemo(
    () =>
      value ? getTimeOption(getHour(value), getMinutes(value)) : defaultText,
    [value, defaultText]
  );
  const availableOptions: string[] = useMemo(
    () => getAvailableOptions(startAt, endAt, interval),
    [startAt, endAt, interval]
  );

  const updateTime = useCallback(
    (newValue: string) => {
      if (!newValue) {
        onChange(undefined);
        return;
      }

      const [h, m] = newValue.split(SEPARATOR).map((item) => Number(item));
      const newTime = h * 3600 + m * 60;

      if (value === newTime) return;

      const validTime =
        (newTime < startAt && startAt) || (newTime > endAt && endAt) || newTime;

      onChange(validTime);
    },
    [endAt, startAt, value, onChange]
  );

  useEffect(() => {
    if (value) updateTime(selectedTimeOption);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className="w-full h-full relative flex items-center text-text-primary"
      id="time-picker-wrapper"
    >
      <Select
        className="w-fit"
        value={selectedTimeOption}
        onChange={(e) => updateTime(e)}
        allowClear
      >
        {availableOptions.map((timeOpt) => {
          return (
            <Select.Option value={timeOpt} key={timeOpt}>
              {timeOpt}
            </Select.Option>
          );
        })}
      </Select>
    </div>
  );
};
