import { faBuildings, faSchool } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AnonymousPropsFlow } from "@kanpla/types";
import { Button } from "antd";
import { useTranslation } from "react-i18next";
import { useContainer } from "unstated-next";
import { SignUpFlowContext } from "..";
import { AppContext } from "../../contextProvider";
import LoginLink from "../LoginLink";
import PageWrapper from "../PageWrapper";

const SchoolOrCompany = ({
  isFromAnonymousFlow = false,
  isFromAPrivateModule = false,
}: AnonymousPropsFlow) => {
  const { supplier } = useContainer(AppContext);
  const { setOnlyCompanySignup, setCurrentPage } =
    useContainer(SignUpFlowContext);

  const { t } = useTranslation(["sign-up", "signup-flow", "translation"]);

  const ExtraContent = () => (
    <div className={`text-center mt-12`}>
      <p className="mb-2">{t("sign-up:question-without-company-email")}</p>
      <Button
        id="signupGoWithoutCompanyEmail"
        className="mt-1 px-10"
        onClick={() => {
          setCurrentPage("canteenId");
        }}
        size="large"
      >
        {t("signup-flow:log-in-with-canteen-id")}
      </Button>
    </div>
  );

  const appName = supplier?.name || supplier?.appName;

  return (
    <PageWrapper
      title={t("signup-flow:choose-school-or-company", {
        name: appName ? `${appName} appen` : "Kanpla",
      })}
      subtitle={t("signup-flow:how-you-will-signup")}
      buttonTitle=""
      hideButton
      extraContent={
        <div className="w-full flex flex-col h-full">
          <ExtraContent />
          {!(isFromAnonymousFlow || isFromAPrivateModule) && (
            <div className="mt-auto">
              {/* <Divider /> */}
              <LoginLink />
            </div>
          )}
        </div>
      }
      isFromAnonymousFlow={isFromAnonymousFlow}
      isFromAPrivateModule={isFromAPrivateModule}
      hideAnonymousAuth
    >
      <div className="flex w-full flex-col pt-8">
        <Button
          icon={<FontAwesomeIcon icon={faSchool} className="mr-2" />}
          className="w-full"
          size="large"
          onClick={() => {
            setCurrentPage("salesplace");
          }}
          data-cy="signup-school-btn"
        >
          {`${t("translation:school")} ${t("translation:or")} ${t(
            "translation:educational"
          )}`}
        </Button>
        <Button
          icon={<FontAwesomeIcon icon={faBuildings} className="mr-2" />}
          size="large"
          className="w-full mt-4"
          onClick={() => {
            setOnlyCompanySignup(true);
            setCurrentPage("email");
          }}
          data-cy="signup-company-btn"
        >
          {t("translation:company")}
        </Button>
      </div>
    </PageWrapper>
  );
};

export default SchoolOrCompany;
