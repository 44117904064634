import { faCheck } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { configLabel, getProductName } from "@kanpla/system";
import {
  CombinedOffer,
  CombinedOfferItem,
  FlexBulkStandard,
  GroupedCombinedOffer,
  IGetCurrentProductsProps,
  Product
} from "@kanpla/types";
import { Form, Select } from "antd";
import classnames from "classnames";
import { isEmpty } from "lodash";
import moment from "moment";
import React, { Dispatch, SetStateAction, useMemo } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  selectedProducts: any;
  label: string;
  products: GroupedCombinedOffer | CombinedOfferItem[] | Product[];
  value: FlexBulkStandard["standard"];
  setActive: (productId: string) => void;
  getCurrentProducts: (props: IGetCurrentProductsProps) => void;
  plainProducts: CombinedOffer;
  daySeconds: number;
  currentDayIndex: number;
  setCurrentDayIndex: Dispatch<SetStateAction<number>>;
  dayIndex: number;
  fromAdmin: boolean;
}

export const Day = ({
  selectedProducts = {},
  label,
  products,
  value,
  setActive,
  getCurrentProducts,
  plainProducts,
  daySeconds,
  currentDayIndex,
  setCurrentDayIndex,
  dayIndex,
  fromAdmin,
}: Props) => {
  const { t, i18n } = useTranslation(["translation"]);

  const hasLanguage = !isEmpty(i18n)

  const productId = Object.keys(value || {})?.[0] || null;

  const today = useMemo(() => {
    return moment().startOf("day").unix() === Number(daySeconds)
      ? ` (${t("translation:today")})`
      : "";
  }, [label]);

  const displayProduct = () => {
    const dateSeconds = Object.entries(selectedProducts).find(
      ([dateSeconds, _]) => {
        const dayName = moment
          .unix(Number(dateSeconds))
          .format("dddd")
          .toLowerCase();

        const isSameDay = dayName === (label as string).toLowerCase();

        return isSameDay;
      }
    )?.[0];

    if (!dateSeconds) return null;

    const productId = selectedProducts?.[dateSeconds]?.id;

    const product = plainProducts.find((product) => product.id === productId);

    const wrapperClassNames = classnames({
      "bg-secondary-dark": !product,
      "bg-blue-600": product,
    });

    const productInfos = getProductName({
      product,
      date: dateSeconds,
      noLunch: hasLanguage ? t("translation:no-breakfast") : "Ingen frokost",
    });

    return (
      <>
        <div
          style={{
            minWidth: "32px",
          }}
          className={`w-8 aspect-square rounded-full self-center flex items-center justify-center ${wrapperClassNames}`}
        >
          {product && (
            <FontAwesomeIcon icon={faCheck} color="white" className="text-lg" />
          )}
        </div>
        <div>
          <p className="text-sm text-secondary-dark">
            {hasLanguage ? t("translation:every") : "Hver"} {`${label}${today}`}
          </p>
          <p className="text-lg">{productInfos.name}</p>
        </div>
      </>
    );
  };

  const dayClassNames = classnames({
    "shadow-lg": currentDayIndex === dayIndex,
  });

  const onClickDay = () => {
    setCurrentDayIndex(dayIndex);
  };

  if (fromAdmin) {
    return (
      <Form.Item
        colon={false}
        label={label}
        labelCol={configLabel({ span: 6 })}
        labelAlign="left"
      >
        <Select
          getPopupContainer={(triggerNode) => {
            return triggerNode.parentNode as HTMLElement;
          }}
          value={productId || "no_lunch"}
          className={`${
            productId === null || productId === "no_lunch"
              ? "danger"
              : "secondary"
          } text-text-secondary`}
          onChange={(value) => setActive(value)}
        >
          {products.map((product) => (
            <Select.Option value={product.id} key={product.id}>
              {product.name}
            </Select.Option>
          ))}
          <Select.Option value="no_lunch">
            {isEmpty(i18n) ? "Ingen frokost" : t("translation:no-breakfast")}
          </Select.Option>
        </Select>
      </Form.Item>
    );
  }

  return (
    <div
      onClick={() => {
        getCurrentProducts({
          label,
          isStandard: true,
          allGroupedItems: products,
        });
        onClickDay();
      }}
      className={`flex px-5 py-4 gap-x-4 items-center rounded-lg transition-all cursor-pointer ${dayClassNames}`}
    >
      {displayProduct()}
    </div>
  );
};
