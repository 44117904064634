import { db, fetchCollection, KanplaError } from "@kanpla/system";
import {
  AnonymousPropsFlow,
  FirestoreDocument,
  ProductBank,
  School,
} from "@kanpla/types";
import { message } from "antd";
import dynamic from "next/dynamic";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useContainer } from "unstated-next";
import { SignUpFlowContext } from "..";
import { AppContext } from "../../contextProvider";
import PageWrapper from "../PageWrapper";

const ReactCodeInput = dynamic(() =>
  import("react-code-input").catch((err) => {
    return () => <p>Failed to load</p>;
  })
);

const CanteenId = ({
  isFromAnonymousFlow = false,
  isFromAPrivateModule = false,
}: AnonymousPropsFlow) => {
  const { t } = useTranslation(["signup-flow", "translation"]);
  const { customBranding } = useContainer(AppContext);
  const { setSchoolId, setCurrentPage, setOnlyCompanySignup, setScope } =
    useContainer(SignUpFlowContext);
  const [code, setCode] = useState<string>("");
  const [loading, setLoading] = useState(false);

  const getSchoolId = async ({ partnerId, code }) => {
    const getByCanteenId = async <T extends FirestoreDocument>(
      collection: string
    ) => {
      const query = db
        .collection(collection)
        .where("canteenId", "==", parseInt(code));
      const withPartnerId = partnerId
        ? query.where("partnerId", "==", partnerId)
        : query;
      const res = await fetchCollection<T>(withPartnerId);
      return res?.[0];
    };

    const [school, productBank] = await Promise.all([
      getByCanteenId<School>("schools"),
      getByCanteenId<ProductBank>("productBanks"),
    ]);

    if (school) return school.id;

    if (productBank) {
      const scope = {
        schools: {},
        productBanks: {
          [productBank.id]: {},
        },
      };
      setScope(scope);
      return productBank.defaultSignupSchoolId;
    }

    return undefined;
  };

  const submit = async () => {
    try {
      setLoading(true);
      if (!code)
        throw new KanplaError("kanpla/fill-canteen-id", "Fill canteen ID out!");

      const partnerId = customBranding?.partnerId || null;

      const schoolId = await getSchoolId({
        partnerId,
        code,
      });

      if (schoolId) {
        message.success(t("signup-flow:message.success.code-is-correct"));
        setSchoolId(schoolId);
        setCurrentPage("email");
        setOnlyCompanySignup(false);
      } else {
        throw new KanplaError("kanpla/wrong-canteen-id", "Wrong canteen ID");
      }
    } catch (e) {
      console.error(e);
      message.error(e?.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (code.length < 4) return;
    submit();
  }, [code]);

  return (
    <PageWrapper
      title={t("signup-flow:enter-canteen-id")}
      buttonTitle={t("translation:confirm")}
      onButtonSubmit={submit}
      showBackButton={true}
      stayOnPageAfterSubmit={true}
      buttonLoading={loading}
      isFromAnonymousFlow={isFromAnonymousFlow}
      isFromAPrivateModule={isFromAPrivateModule}
    >
      <p className="text-center leading-6">
        {t("signup-flow:can-find-your-canteen-id")}
      </p>
      <p className="text-center leading-6 pt-2">
        {t("signup-flow:enter-the-4-digit")}
      </p>
      <div className="pb-2 pt-6 flex justify-center">
        <ReactCodeInput
          type="number"
          className="w-full grid grid-cols-4"
          inputStyle={{
            fontFamily: "inherit",
            padding: 12,
            border: "1px solid",
            borderRadius: 6,
            margin: 6,
            textAlign: "center",
            fontSize: 22,
            fontWeight: "600",
            borderColor: "var(--palette-primary-main)",
          }}
          style={{
            display: "grid !important",
          }}
          value={code}
          onChange={(newValue) => {
            setCode(newValue);
          }}
          fields={4}
          name="emailCode"
          inputMode="numeric"
        />
      </div>
    </PageWrapper>
  );
};

export default CanteenId;
