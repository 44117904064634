import { faEmptySet } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getActivePlugins, getOrderConfigs } from "@kanpla/system";
import { Module, OrderInfo as OrderInfoType, OrderOrder } from "@kanpla/types";
import { Button, ButtonProps, Divider, Empty, FormInstance } from "antd";
import classnames from "classnames";
import { isEmpty, sortBy } from "lodash";
import moment from "moment";
import React, { Dispatch, FC, SetStateAction, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { createContainer, useContainer } from "unstated-next";
import { BasketListItem, OrderInfoItem } from ".";
import { OrderingContext } from "../../context";
import { EditModeType } from "./BasketListTypes";

interface StateProps {
  basket: OrderOrder;
  setBasket: Dispatch<SetStateAction<OrderOrder>>;
}

export const BasketState = ({ basket, setBasket }: StateProps) => {
  return { basket, setBasket };
};

export const BasketContext = createContainer(BasketState);

export interface WithEditType {
  /** Handle different flows depending where `BasketList` is being used on */
  type: "receipt" | "meeting" | "mealplan";
  editType?: EditModeType;
  disableEditing?: boolean;
  title?: string | JSX.Element;
  basket?: OrderOrder;
  setBasket?: Dispatch<SetStateAction<OrderOrder>>;
  buttonProps?: ButtonProps & React.RefAttributes<HTMLElement>;
  buttonText?: string;
  /** Makes the basket blurry */
  anonymous?: boolean;
  /** Check if the basket list if from a receipt or not */
  hideHeader?: boolean;
  form?: FormInstance;
  editModalOpen?: boolean;
  setEditModalOpen?: Dispatch<SetStateAction<boolean>>;
  showOrderInfo?: boolean;
  orderInfo?: OrderInfoType;
}

interface AlwaysEditableBasketListProps extends WithEditType {
  alwaysEditable?: true;
  editMode?: boolean;
  setEditMode?: Dispatch<SetStateAction<boolean>>;
  /** For anonymous users */
  hideEditButton?: boolean;
}

interface EditableBasketListProps extends WithEditType {
  alwaysEditable?: false;
  editMode?: boolean;
  /** Required when `alwaysEditable` is false. */
  setEditMode: Dispatch<SetStateAction<boolean>>;
  /** For anonymous users */
  hideEditButton?: boolean;
}

interface EditableBasketListPropsWithBasket extends EditableBasketListProps {
  basket: OrderOrder;
  setBasket: Dispatch<SetStateAction<OrderOrder>>;
}

/** `setEditMode` is required when `alwaysEditable` is false. */
type BasketListProps =
  | EditableBasketListProps
  | EditableBasketListPropsWithBasket
  | AlwaysEditableBasketListProps;

export const BasketList: FC<BasketListProps> = ({
  editType = "both",
  type,
  alwaysEditable = false,
  editMode = false,
  hideEditButton = false,
  disableEditing = false,
  hideHeader = false,
  basket: propsBasket,
  setBasket: setPropsBasket,
  setEditMode,
  form = null,
  title,
  buttonProps,
  anonymous,
  setEditModalOpen,
  showOrderInfo,
  orderInfo,
}) => {
  const {
    basket: contextBasket,
    setBasket: setContextBasket,
    modules,
    basketContainer,
    basketContainerUtils,
  } = useContainer(OrderingContext);

  const basket = propsBasket || contextBasket;
  const setBasket = setPropsBasket || setContextBasket;

  const { t, i18n } = useTranslation([
    "translation",
    "design",
    "modals",
    "mealplan2",
  ]);

  const hasLanguage = !isEmpty(i18n);

  const usesRegularBasket = type === "receipt" || type === "meeting";

  const configs = useMemo(
    () => (usesRegularBasket && getOrderConfigs(basket)) || [],
    [basket, usesRegularBasket]
  );

  /** The list can be always editable for popup views. */
  const isEditMode = alwaysEditable || editMode;

  const basketClassName = classnames({
    "w-full": true,
    "filter blur-sm pointer-events-none select-none": anonymous,
  });

  const isBasketEmpty =
    (type === "mealplan" && isEmpty(basketContainer)) ||
    (usesRegularBasket && isEmpty(configs));

  return (
    <BasketContext.Provider initialState={{ basket, setBasket }}>
      <div className={`${basketClassName}`}>
        <div className="flex justify-between items-center">
          {!hideHeader && (
            <>
              <h1 className="h500 my-2">{title || t("design:basket")}</h1>
              {!alwaysEditable && !disableEditing && !hideEditButton && (
                <Button
                  size="small"
                  onClick={() => {
                    setEditModalOpen?.(true);
                    setEditMode?.((prevState) => !prevState);
                  }}
                  {...buttonProps}
                >
                  {t("translation:edit")}
                </Button>
              )}
            </>
          )}
        </div>
        {isBasketEmpty && (
          <div className="my-6 py-4 text-text-secondary rounded-lg bg-background-secondary ">
            <Empty
              description={
                hasLanguage
                  ? t("modals:no-bookings-short")
                  : "Ingen bestillinger"
              }
              image={<FontAwesomeIcon icon={faEmptySet} className="text-sm" />}
              imageStyle={{ fontSize: 10 }}
            />
          </div>
        )}

        {/* Cross Basket */}
        {!usesRegularBasket &&
          Object.entries(basketContainerUtils.orderedBasket || {}).map(
            ([dateSeconds, ordersInModules], i) => {
              return (
                <div key={dateSeconds}>
                  <div className="font-medium font-lg my-2 text-primary-main">
                    {moment.unix(parseInt(dateSeconds)).format("dddd DD/MM")}
                  </div>
                  {Object.entries(ordersInModules || {}).map(
                    ([moduleId, item]) => {
                      const configs = getOrderConfigs(item.order);
                      const targetModule = modules.find(
                        (m: Module) => m.id === moduleId
                      );
                      const activePlugins = getActivePlugins({
                        module: targetModule,
                      });

                      const hasActivePlugins =
                        targetModule?.flow === "meeting" ||
                        activePlugins["timeInput"] ||
                        activePlugins["textInput"] ||
                        activePlugins["orderForAmountOfPeople"] ||
                        activePlugins["requiredProduct"] ||
                        activePlugins["invoiceReference"];

                      return (
                        <div key={moduleId}>
                          <div className="my-2 font-medium uppercase text-text-secondary">
                            {targetModule?.name}
                          </div>
                          {configs.map((item, index) => (
                            <div
                              key={`${item.config.uid}-${item.productId}-${index}`}
                            >
                              <BasketListItem
                                hasGap={usesRegularBasket}
                                item={item}
                                editMode={isEditMode}
                                editType={editType}
                                type={type}
                                basket={
                                  basketContainerUtils.findTargetBasket(
                                    moduleId,
                                    parseInt(dateSeconds)
                                  )?.order || {}
                                }
                                setBasket={(o) =>
                                  basketContainerUtils.setBasketFromDifferentModule(
                                    {
                                      o,
                                      moduleId,
                                      dateSeconds: parseInt(dateSeconds),
                                    }
                                  )
                                }
                                orderInfo={orderInfo}
                              />
                            </div>
                          ))}
                          {form && hasActivePlugins && (
                            <OrderInfoItem
                              module={targetModule}
                              dateSeconds={parseInt(dateSeconds)}
                              form={form}
                            />
                          )}
                        </div>
                      );
                    }
                  )}
                  {Object.keys(basketContainerUtils.orderedBasket || {})
                    .length !==
                    i + 1 && <Divider />}
                </div>
              );
            }
          )}

        {usesRegularBasket &&
          sortBy(configs, "name").map((item, index) => (
            <BasketListItem
              key={item.config.uid}
              type={type}
              hasGap={usesRegularBasket}
              item={item}
              editMode={isEditMode}
              editType={editType}
              basket={basket}
              setBasket={(o) => setBasket(o, orderInfo)}
              isLast={configs.length - 1 === index}
              form={form}
              showOrderInfo={showOrderInfo}
              orderInfo={orderInfo}
            />
          ))}
      </div>
    </BasketContext.Provider>
  );
};
