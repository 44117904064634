import { faChevronRight } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CombinedOfferItem } from "@kanpla/types";
import { Collapse } from "antd";
import classnames from "classnames";
import React, { useMemo } from "react";
import { Props as NoLunchButtonProps } from "./NoLunchButton";
import { Props as ProductItemProps } from "./ProductItem";

interface Props {
  ProductItem: (props: ProductItemProps) => JSX.Element;
  NoLunchButton: (props: NoLunchButtonProps) => JSX.Element;
  productItemProps: Omit<ProductItemProps, "product">;
  noLunchButtonProps: NoLunchButtonProps;
  mobile: boolean;
  isBulk: boolean;
  isHolidayFromUser: boolean;
  fromStandard: boolean;
  categories?: {
    [key: string]: CombinedOfferItem[];
  };
  singleProducts?: CombinedOfferItem[];
}

export const ProductsListView = (props: Props) => {
  const {
    ProductItem,
    NoLunchButton,
    categories,
    mobile,
    productItemProps,
    noLunchButtonProps,
    singleProducts,
    isBulk,
    isHolidayFromUser,
    fromStandard,
  } = props;

  const { isMultipleCategories, isPast } = productItemProps;
  const { isHolidayFromAdmin } = noLunchButtonProps;

  const parentCategoryClassNames = useMemo(() => {
    return classnames({
      "bg-background-secondary rounded": true,
      "pointer-events-none": isPast,
    });
  }, [isPast]);

  const wrapperCategoryClassNames = useMemo(() => {
    return classnames({
      "opacity-30 pointer-events-none":
        isPast || isHolidayFromUser || isHolidayFromAdmin,
      "flex flex-wrap gap-6": true,
      "justify-between": !isMultipleCategories,
    });
  }, [isHolidayFromUser, isMultipleCategories, isHolidayFromAdmin, isPast]);

  if (singleProducts?.length) {
    return (
      <div className={fromStandard || mobile ? "" : wrapperCategoryClassNames}>
        {singleProducts?.map((product) => {
          return (
            <ProductItem
              {...productItemProps}
              product={product}
              full={fromStandard || Boolean(mobile && singleProducts)}
            />
          );
        })}
        {!isBulk && (
          <NoLunchButton
            {...noLunchButtonProps}
            full={fromStandard || Boolean(mobile && singleProducts)}
          />
        )}
      </div>
    );
  }

  return (
    <div className={fromStandard || mobile ? "" : wrapperCategoryClassNames}>
      {Object.entries(categories || {}).map(
        ([category, productsDay], index) => {
          if (fromStandard || mobile) {
            return (
              <Collapse
                key={`${category}-${index}`}
                expandIcon={({ isActive }) => {
                  return (
                    <FontAwesomeIcon
                      icon={faChevronRight}
                      className={`!text-xl transition-all ${
                        isActive
                          ? "!transform !rotate-90 !-translate-y-1/2"
                          : "!transform !rotate-0 !-translate-y-1/2"
                      }`}
                    />
                  );
                }}
                bordered={false}
                expandIconPosition="end"
                className="bg-background-secondary rounded-md mb-3"
              >
                <Collapse.Panel
                  header={<h4 className="h400">{category || "Dagens ret"}</h4>}
                  key={index}
                >
                  {productsDay.map((product) => {
                    return (
                      <ProductItem {...productItemProps} product={product} />
                    );
                  })}
                </Collapse.Panel>
              </Collapse>
            );
          }

          return (
            <div
              style={{
                width: "calc(33.333333% - 24px)",
              }}
              className={parentCategoryClassNames}
            >
              <div className="px-3 py-2">
                <h4 className="h400 mb-4">{category || "Dagens ret"}</h4>
                {productsDay?.map((product) => {
                  return (
                    <ProductItem {...productItemProps} product={product} />
                  );
                })}
              </div>
            </div>
          );
        }
      )}
      {!isBulk && (
        <NoLunchButton {...noLunchButtonProps} full={fromStandard || mobile} />
      )}
    </div>
  );
};
