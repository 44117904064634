import { rules } from "@kanpla/system";
import { Form, Input } from "antd";
import { useTranslation } from "react-i18next";
import React from "react";
import { useContainer } from "unstated-next";
import { SignUpFlowContext } from "..";

interface Props {
  label: string;
}

const NameInput = (props: Props) => {
  const { t } = useTranslation(["translation"]);
  const { name, setName } = useContainer(SignUpFlowContext);
  const { label = t("translation:name") } = props;
  return (
    <Form.Item
      name="field-name"
      label={label}
      rules={[
        rules({
          rule: "required",
          ruleValue: true,
          message: t("translation:form.errors.required-en", {
            value: t("translation:name").toLowerCase(),
          }),
        }),
      ]}
    >
      <Input
        id="new-name-input"
        placeholder={t("translation:ph-name")}
        type="text"
        value={name || ""}
        onChange={(e) => setName(e.target.value)}
      />
    </Form.Item>
  );
};

export default NameInput;
