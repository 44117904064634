import { Image, NewLogo } from "@kanpla/ui";
import classNames from "classnames";
import React from "react";
import { useContainer } from "unstated-next";
import { AppContext } from "./contextProvider";

interface Props {
  children: React.ReactElement;
}

const LandingWrapper = (props: Props) => {
  const { children } = props;

  const { customBranding, customBrandingLoading, mobile } =
    useContainer(AppContext);

  const landingBackgroundType = mobile ? "mobile" : "desktop";
  const landingBackgroundImage =
    customBranding?.custom?.images?.landingBackgroundImage;

  const backgroundImage = !customBrandingLoading
    ? landingBackgroundImage?.[landingBackgroundType] ||
      // If mobile image is not available, fallback to the desktop one
      (mobile && landingBackgroundImage?.["desktop"]) ||
      customBranding?.custom?.images?.landingBackground ||
      "/images/cooking-hero-join.jpeg"
    : null;

  return (
    <div
      id="landing-app-fullscreen"
      className="w-full h-full relative overflow-hidden"
    >
      <div className="z-0 fixed inset-0 overflow-hidden">
        <div className="bg-primary-main bg-opacity-30 absolute inset-0 z-10" />
        <div className="bg-gradient-to-tl from-black opacity-70 absolute inset-0 z-10" />
        <Image
          src={backgroundImage}
          alt={undefined}
          className={classNames({
            "absolute inset-0 z-0 w-full h-full object-cover transition-opacity delay-300":
              true,
            "opacity-0": !backgroundImage,
          })}
        />
      </div>
      <div className="relative md:flex md:justify-center md:items-center w-full p-0 md:p-4 z-40 h-screen overflow-hidden">
        <div className="flex flex-col justify-between md:justify-center w-full h-full p-0 md:p-4 fixed inset-0">
          <div className="w-full flex justify-center my-10 md:my-4">
            <NewLogo centered type="white" style={{ maxWidth: 150 }} />
          </div>
          <div className="relative flex w-full h-full md:h-auto md:min-h-[20rem] md:max-h-[40rem] justify-center mt-0 md:mt-8 overflow-hidden">
            <div className="bg-background-primary md:bg-opacity-90 rounded-none md:rounded-xl backdrop-blur-sm flex flex-col items-center md:flex-none md:items-start pt-7 px-5 md:px-8 py-5 w-full md:w-[35rem] md:max-w-4xl shadow-md lg:shadow-2xl overflow-y-auto">
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingWrapper;
