/* eslint-disable @typescript-eslint/ban-ts-comment */
import { isSchoolSelectorInScope, validateRequiredOrder } from "@kanpla/system";
import { OrderInfo, OrderOrder, Plugins } from "@kanpla/types";
import { ProductSettingsHeader } from "@kanpla/ui";
import { Form, Select } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { isEmpty } from "lodash";
import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useLocalstorageState } from "rooks";

const { Option } = Select;

interface Props {
  settings: Plugins.TextInput;
  orderInfo: OrderInfo;
  schoolId: string;
  groupName?: string;
  noBg?: boolean;
  extraKey?: string;
  orderDocument: OrderOrder;
  /**
   * For better separation, you can send in how you want it filtered...
   * If it's empty, it won't filter
   */
  filter?: "required" | "notRequired";
}

const TextInputInner = ({ field, value, onChange }) => {
  const { t, i18n } = useTranslation(["libs"]);
  const [defaultSelect, setDefaultSelect] = useLocalstorageState<string>(
    `text-input-${field?.key}`,
    ""
  );

  useEffect(() => {
    if (isTextInput || !defaultSelect) return;
    if (defaultSelect === "undefined") return;

    onChange(defaultSelect);
  }, []);

  const isTextInput = field?.type === "text" || !field?.type;
  const isSelectInput =
    field?.type === "select" && field?.type && !field?.isMultiSelect;
  const isMultiSelectInput =
    field?.type === "select" && field?.type && field?.isMultiSelect;

  if (isTextInput)
    return (
      <TextArea
        required={field?.required}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        placeholder={field.placeholder || field?.title || ""}
        autoSize={{
          minRows: field.minRows || 1,
          maxRows: field.maxRows || 3,
        }}
      />
    );

  if (isSelectInput)
    return (
      <Select
        className="my-1"
        placeholder={
          isEmpty(i18n) ? "Vælg en mulighed" : t("libs:select-an-option")
        }
        value={typeof value === "string" ? value : value?.[0]}
        onChange={(_value) => {
          setDefaultSelect(_value);
          onChange(_value);
        }}
      >
        {field.options.map((option, i) => (
          <Option key={i} value={option}>
            {option}
          </Option>
        ))}
      </Select>
    );

  if (isMultiSelectInput)
    return (
      <Select
        className="my-1"
        mode={"multiple"}
        placeholder={
          isEmpty(i18n)
            ? "Vælg en eller flere muligheder"
            : t("libs:select-one-or-more")
        }
        value={value}
        onChange={(_value) => {
          setDefaultSelect(_value);
          onChange(_value);
        }}
      >
        {field.options.map((option, i) => (
          <Option key={i} value={option}>
            {option}
          </Option>
        ))}
      </Select>
    );
};

export const TextInput = (props: Props) => {
  const {
    settings,
    orderInfo = {},
    schoolId,
    groupName = "",
    noBg,
    extraKey = "",
    orderDocument,
    filter,
  } = props;
  const { t, i18n } = useTranslation(["libs"]);
  const { textInput } = orderInfo || {};

  const hasExtraKey = extraKey ? "." + extraKey : "";

  // useEffect(() => {
  //   if (isEmpty(value)) onChange(textInput);
  // }, [textInput]);

  // Filter out fields disabled for this school
  const availableFields = useMemo(
    () =>
      settings?.fields?.filter((f) => {
        // Fallback for the old text input plugin
        if (f.scope) {
          const isAvailable = isSchoolSelectorInScope({
            scope: f.scope,
            schoolId,
            groupName,
          });
          const fulfillsRequiredOrder = validateRequiredOrder(
            orderDocument,
            f.requiredOrder
          );
          return isAvailable && fulfillsRequiredOrder;
        }
        return !(f.disabledSchoolIds || []).includes(schoolId);
      }) || [],
    [orderDocument, groupName, schoolId, settings?.fields]
  );

  if (availableFields.length === 0) return null;

  return (
    <>
      {availableFields
        .filter((field) => {
          if (!filter) return true;

          if (filter === "required" && field.required) return true;
          if (filter === "notRequired" && !field?.required) return true;

          return false;
        })
        .map((field) => {
          return (
            <Form.Item
              key={field.key}
              name={`textInput.${field.key}${hasExtraKey}`}
              initialValue={textInput?.[field.key]}
              rules={[
                {
                  required: field?.required,
                  message: isEmpty(i18n)
                    ? "Dette felt er påkrævet"
                    : t("libs:specific-field-required", {
                        fieldName: field?.title,
                      }),
                },
              ]}
              label={
                <ProductSettingsHeader
                  title={field.title}
                  subtitle={field.subtitle}
                  noBg={noBg}
                />
              }
            >
              {/* @ts-ignore */}
              <TextInputInner field={field} />
            </Form.Item>
          );
        })}
    </>
  );
};
