import { callInternalApi } from "@kanpla/system";
import { Button, message } from "antd";
import {
  SubmitEmailVerificationStateProps,
  SubmitEmailVerificationStateReturn,
} from "apps/frontend/pages/api/internal/submit/emailVerificationState";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useContainer } from "unstated-next";
import { SignUpFlowContext } from ".";
import Loading from "./Loading";

interface Props {
  children: JSX.Element;
}

const MagicLinkListener = ({ children }: Props) => {
  const { t } = useTranslation(["signup-flow"]);
  const router = useRouter();
  const { setCurrentPage, setEmail, setSchoolId } =
    useContainer(SignUpFlowContext);
  const [codeFailed, setCodeFailed] = useState(false);

  const email = router.query?.email || "";
  const schoolId = router.query?.schoolId || "";
  const code = router.query?.code;

  const hasMagicLink = typeof email === "string" && typeof code === "string";

  const validateCode = async (email: string, code: string) => {
    try {
      await callInternalApi<
        SubmitEmailVerificationStateProps,
        SubmitEmailVerificationStateReturn
      >("submit/emailVerificationState", {
        code,
        email,
      });

      message.success(t("signup-flow:message.success.code-correct"));
      router.push({
        query: {},
      });

      setEmail(email);
      setSchoolId(schoolId as string);
      setCurrentPage("user");
    } catch (err) {
      message.error(t("signup-flow:message.error.wrong-code"));
      setCodeFailed(true);
    }
  };

  useEffect(() => {
    if (typeof email !== "string" || typeof code !== "string") return;
    validateCode(email, code);
  }, [email, code]);

  if (hasMagicLink && codeFailed)
    return (
      <div className="flex flex-col justify-center items-center h-full w-full">
        <h2 className="h600 mb-8">{t("signup-flow:confirmation-failed")}</h2>

        <Button
          type="primary"
          onClick={() => {
            setCurrentPage("emailCode");
            router.push({
              query: {},
            });
          }}
        >
          {t("signup-flow:enter-code-manually")}
        </Button>
      </div>
    );

  if (hasMagicLink) return <Loading />;

  return children;
};

export default MagicLinkListener;
