import { faCalendarDay } from "@fortawesome/pro-light-svg-icons";
import { fetchMultipleDocuments, fn } from "@kanpla/system";
import {
  AnonymousPropsFlow,
  FlexStandard,
  Module,
  Product,
} from "@kanpla/types";
import { Button, Select, message } from "antd";
import { createStandardWeek } from "apps/frontend/lib/flex/createStandardWeek";
import { isEmpty } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useContainer } from "unstated-next";
import { SignUpFlowContext } from "..";
import { AppContext } from "../../contextProvider";
import PageWrapper from "../PageWrapper";
import SignupFlowStandardOrderModalsWrapper from "../modals/SignupFlowStandardOrderModalsWrapper";

interface Props extends AnonymousPropsFlow {
  module: Module;
}

const StandardOrder = ({
  isFromAnonymousFlow = false,
  isFromAPrivateModule = false,
  module,
}: Props) => {
  const { t, i18n } = useTranslation([
    "signup-flow",
    "translation",
    "introduction",
    "flex-bulk",
    "libs",
  ]);

  const {
    child,
    schoolId,
    standardWeek,
    setStandardProducts,
    standardProducts,
    setStandardWeek,
  } = useContainer(SignUpFlowContext);
  const { week } = useContainer(AppContext);

  const [openStandardOrder, setOpenStandardOrder] = useState(false);
  const [openVariants, setOpenVariants] = useState(false);

  const [products, setProducts] = useState<Array<Product>>([]);
  const [productsLoading, setProductsLoading] = useState(false);
  const [standard, setStandard] = useState<FlexStandard["standard"]>({});

  /**
   * Disabled because if was breaking the "invitation-link" flow for DABBA
   * Let's look at this together @petrmrkvicka
   *
   * Yours sincerely,
   * @robertkanpla
   * 20.7.2022
   */

  useEffect(() => {
    (async () => {
      try {
        if (!module?.id) throw new Error("Missing module");

        setProductsLoading(true);

        const productIds = Object.keys(module.products || {});
        const productsLoaded = await fetchMultipleDocuments<Product>(
          "products",
          productIds
        );

        const filteredProducts = productsLoaded.filter((product) => {
          const productInModule = module.products?.[product?.id];

          if (!productInModule?.active) return false;

          return !productInModule?.schools?.[schoolId]?.disabled;
        });

        setProducts(filteredProducts);
      } catch (e) {
        console.error(e);
        message.error(e?.message);
      } finally {
        setProductsLoading(false);
      }
    })();
  }, [module, schoolId]);

  const submit = async () => {
    try {
      message.loading(
        isEmpty(i18n)
          ? "Standarder gemmes"
          : t("translation:message.loading.standards-are-saved"),
        0
      );

      Object.values(standardWeek || standard || {})?.map((s) => {
        if (Object.keys(s || {})?.length > 1)
          throw new Error(t("translation:message.error.too-many-products"));
        return true;
      });

      // Delete empty lunches before submitting
      delete (standardWeek || standard)?.[0]?.no_lunch;
      delete (standardWeek || standard)?.[1]?.no_lunch;
      delete (standardWeek || standard)?.[2]?.no_lunch;
      delete (standardWeek || standard)?.[3]?.no_lunch;
      delete (standardWeek || standard)?.[4]?.no_lunch;

      const submitToServer = fn.httpsCallable("submitFlexStandard");
      await submitToServer({
        standard: standardWeek || standard,
        schoolId,
        moduleId: module?.id,
        userId: child?.userId,
        childId: child?.id,
        fromAdmin: undefined,
      });

      message.destroy();
      message.success(
        isEmpty(i18n)
          ? "Standarder opdateret."
          : t("translation:message.success.standards-updated")
      );
    } catch (err) {
      console.error(err);
      message.error(
        err?.message ||
          (isEmpty(i18n)
            ? "Ændringer kunne ikke gemmes."
            : t("translation:message.error.changes-could-not-be-saved"))
      );
    }
  };

  const modalsProps = useMemo(() => {
    const props = {
      openStandardOrder,
      setOpenStandardOrder,
      openVariants,
      setOpenVariants,
      module,
    };

    return props;
  }, [openStandardOrder, openVariants, module]);

  const changeStandardWeek = (productId: string) => {
    const flexWeek = Object.fromEntries(
      [0, 1, 2, 3, 4].map((day) => [day, { [productId]: { amount: 1 } }])
    );
    const flexWeekWithDateSeconds = createStandardWeek({ week, productId });

    setStandardProducts(flexWeekWithDateSeconds);
    setStandardWeek(flexWeek);
    setStandard(flexWeek);
  };

  const areDaysAllEqual = useMemo(() => {
    const productId = Object.keys(standard?.[0] || {})?.[0];

    const entries = Object.entries(standardProducts || {});
    const equal = entries.every(
      (entry) => entry?.[1]?.id === productId && productId !== "no_lunch"
    );

    return equal;
  }, [standardProducts, standard]);

  const valueProp = useMemo(() => {
    const productId = Object.keys(standard?.[0] || {})?.[0];
    const prop = areDaysAllEqual ? { value: productId } : { value: null };

    return prop;
  }, [areDaysAllEqual, standard]);

  return (
    <PageWrapper
      buttonTitle={t("translation:continue")}
      title={t("introduction:enter-default-order")}
      subtitle={t("flex-bulk:set-default-choice")}
      topIllustration={faCalendarDay}
      onButtonSubmit={submit}
      isFromAnonymousFlow={isFromAnonymousFlow}
      isFromAPrivateModule={isFromAPrivateModule}
    >
      {!productsLoading && (
        <div className="w-full h-full my-10 text-primary-dark">
          <Select
            size="large"
            placeholder={t("translation:select-product")}
            className="w-full"
            options={[
              { name: t("translation:no-breakfast"), id: "no_lunch" },
              ...products,
            ].map((p) => ({ label: p.name, value: p.id }))}
            onChange={(value) => {
              changeStandardWeek(value);
            }}
            {...valueProp}
          />
          <Button
            onClick={() => setOpenStandardOrder(true)}
            type="link"
            className="text-text-primary my-2 text-sm w-full"
          >
            {t("libs:edit-default-choice")}
          </Button>
          <SignupFlowStandardOrderModalsWrapper {...modalsProps} />
        </div>
      )}
    </PageWrapper>
  );
};

export default StandardOrder;
