import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faChevronLeft } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AUTHENTICATION_MS, useWindowSize } from "@kanpla/system";
import {
  ActionsAuthenticationModal,
  AnonymousPropsFlow,
  DataAuthenticationModal,
} from "@kanpla/types";
import { Button as ButtonAntd, Form, message } from "antd";
import classNames from "classnames";
import { useRouter } from "next/router";
import { ReactNode, useState } from "react";
import { useTranslation } from "react-i18next";
import { useContainer } from "unstated-next";
import { AppContext } from "../contextProvider";
import Helpcenter from "../Helpcenter";
import Button from "./ButtonSignUp";
import { SignUpFlowContext } from "./index";
import PageHeader from "./PageHeader";

interface Props extends AnonymousPropsFlow {
  title: string;
  titleSize?: "h500" | "h600" | "h700" | "h800";
  subtitle?: ReactNode;
  topIllustration?: string | IconProp;
  illustration?: string;
  buttonTitle: string;
  onButtonSubmit?: () => void | Promise<void>;
  onButtonClick?: () => void | Promise<void>;
  children: ReactNode;
  hideButton?: boolean;
  disableButton?: boolean;
  showBackButton?: boolean;
  buttonLoading?: boolean;
  extraContent?: ReactNode;
  skipButton?: boolean;
  stayOnPageAfterSubmit?: boolean;
  largerWidth?: boolean;
  htmlType?: "button" | "submit";
  dataCy?: string;
  hideAnonymousAuth?: boolean;
}

const PageWrapper = (props: Props) => {
  const {
    title,
    titleSize,
    subtitle = null,
    buttonTitle,
    illustration = null,
    children,
    onButtonSubmit = null,
    onButtonClick = null,
    hideButton = false,
    disableButton,
    showBackButton = false,
    extraContent = null,
    skipButton = false,
    topIllustration,
    stayOnPageAfterSubmit = false,
    buttonLoading,
    largerWidth = false,
    htmlType = "submit",
    isFromAnonymousFlow = false,
    isFromAPrivateModule = false,
    dataCy = "",
    hideAnonymousAuth = false,
  } = props;
  const { t } = useTranslation(["translation", "signup-flow", "anonymous"]);

  const { currentIndex, goToNextScreen } = useContainer(SignUpFlowContext);
  const { setDataAuthenticationModal, setOpenAuthenticationModal } =
    useContainer(AppContext);
  const [loading, setLoading] = useState<boolean>(false);

  const { width: screenWidth } = useWindowSize();
  const router = useRouter();

  const onSubmit = async ({
    submit,
    data,
  }: {
    submit: (data: any) => void | Promise<void>;
    data: any;
  }) => {
    try {
      setLoading(true);
      await submit(data);
      if (!stayOnPageAfterSubmit) goToNextScreen();
    } catch (err) {
      console.error(err);
      message.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  /** Set the data of the authentication modal when the user is about to order */
  const setDataForAuthenticationModalAndOpenModal = (
    title: string,
    subtitle: string,
    action: ActionsAuthenticationModal,
    isFromAPrivateModule?: boolean
  ) => {
    if (!isFromAPrivateModule) setOpenAuthenticationModal(false);
    setTimeout(() => {
      setDataAuthenticationModal(() => {
        const newState: DataAuthenticationModal = {
          title,
          subtitle,
          action,
        };
        return newState;
      });
      if (!isFromAPrivateModule) setOpenAuthenticationModal(true);
    }, AUTHENTICATION_MS);
  };

  return (
    <div
      className="flex flex-col md:flex-row w-full h-full "
      style={{
        backgroundImage:
          illustration && screenWidth < 768 ? `url(${illustration || ""})` : "",
        backgroundPosition:
          screenWidth < 768 ? "right -10% bottom" : "center bottom",
        backgroundSize: screenWidth < 768 ? "140%" : "60%",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div className="w-full h-full md:flex md:justify-center">
        <div className="w-full h-full flex flex-col items-center">
          {!isFromAnonymousFlow && !isFromAPrivateModule && showBackButton && (
            <div className="flex w-full">
              <ButtonAntd
                type="text"
                onClick={() =>
                  !currentIndex ? router.push("/") : window.history.back()
                }
              >
                <FontAwesomeIcon icon={faChevronLeft} className="mr-2" />
                {t("translation:back")}
              </ButtonAntd>
            </div>
          )}
          <div
            className={`${
              !isFromAnonymousFlow &&
              !isFromAPrivateModule &&
              "w-full h-full pb-8"
            } ${
              largerWidth ? "max-w-2xl" : "max-w-md"
            } md:flex md:flex-col md:items-center mx-auto`}
          >
            {topIllustration &&
              (typeof topIllustration === "string" ? (
                <img
                  src={topIllustration}
                  alt=""
                  className="mt-6 -mb-6 mx-auto"
                />
              ) : (
                <FontAwesomeIcon
                  icon={topIllustration}
                  className="text-7xl text-text-secondary"
                />
              ))}
            <div
              className={classNames(
                !isFromAnonymousFlow && !isFromAPrivateModule && "mt-10"
              )}
            >
              <PageHeader
                titleSize={titleSize}
                title={title}
                titleDataCy={dataCy}
                subtitle={subtitle}
              />
            </div>
            <div className="w-full h-full max-w-sm pt-0 mx-auto pb-16 md:pb-0">
              <Form
                className="w-full h-full flex flex-col items-center justify-center"
                layout="vertical"
                onFinish={(data) =>
                  onButtonSubmit && onSubmit({ submit: onButtonSubmit, data })
                }
                // To catch the submit event on a custom form
                onSubmitCapture={(event) => event.preventDefault()}
              >
                <div className="w-full mt-8 mx-auto">{children}</div>
                {!hideButton && (
                  <div className="mt-6 w-full">
                    <Button
                      title={buttonTitle}
                      loading={loading || buttonLoading}
                      height={screenWidth > 768 ? "small" : "medium"}
                      type={htmlType}
                      disabled={disableButton}
                      onClick={() => {
                        onButtonClick && onButtonClick();
                      }}
                    />
                  </div>
                )}
                {skipButton && (
                  <div className=" my-8 flex justify-center">
                    <ButtonAntd
                      type="text"
                      className="text-center mx-auto text-lg font-semibold"
                      size="large"
                      onClick={() => goToNextScreen()}
                    >
                      {t("signup-flow:add-later")}
                    </ButtonAntd>
                  </div>
                )}
                {!hideAnonymousAuth &&
                  (isFromAnonymousFlow || isFromAPrivateModule) && (
                    <p className="mt-4 text-text-secondary">
                      {t("translation:already-account")}{" "}
                      <span
                        onClick={() => {
                          setDataForAuthenticationModalAndOpenModal(
                            t("translation:welcome-back"),
                            t("translation:log-in-to-your-account"),
                            "login",
                            isFromAPrivateModule
                          );
                        }}
                        className="font-semibold text-primary-main cursor-pointer"
                      >
                        {t("translation:log-in-here")}
                      </span>
                    </p>
                  )}
                {extraContent}
                <div className="mt-auto">
                  <div className="mt-3 mx-auto flex justify-center">
                    <Helpcenter.Intercom small />
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageWrapper;
