import { IBaseProducts, Week } from "@kanpla/types";

interface Props {
  week: Week;
  productId: string;
}

export const createStandardWeek = ({ week, productId }: Props) => {
  const standard: IBaseProducts = week.reduce((acc, day) => {
    const seconds = day.seconds;

    return {
      ...acc,
      [seconds]: { id: productId, dateSeconds: seconds },
    };
  }, {});

  return standard;
};
