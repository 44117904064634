import {
  CombinedOffer,
  FlexBulkStandard,
  GroupedCombinedOffer,
} from "@kanpla/types";

interface Props {
  /** All items grouped by date seconds and category */
  allGroupedItems: GroupedCombinedOffer;
  /** Current standard orders */
  standard: FlexBulkStandard["standard"];
  /** Items grouped by date seconds and category, without deleted and not available ones */
  items: CombinedOffer;
}

/**
 * This function set the object with the standard products
 */
export const setBaseStandardProducts = ({
  allGroupedItems,
  standard,
  items,
}: Props) => {
  const products = Object.keys(allGroupedItems || {}).reduce(
    (acc, dateSeconds, index) => {
      const standardProduct = {
        id:
          items?.find((item) => {
            const standardIds = Object.keys(standard?.[index] || {});
            const itemIsPresent = standardIds?.includes(item?.id);

            return itemIsPresent;
          })?.id || "noLunch",
        dateSeconds: dateSeconds.toString(),
      };
      return {
        ...acc,
        [dateSeconds]: standardProduct,
      };
    },
    {}
  );

  return products;
};
