import {
  CombinedOfferItem,
  GroupedCombinedOffer,
  IGetCurrentProductsProps,
} from "@kanpla/types";
import moment from "moment";
import { Dispatch, SetStateAction } from "react";

interface Props extends IGetCurrentProductsProps {
  groupedItems?: GroupedCombinedOffer;
  actions?: {
    setDayProducts?: Dispatch<
      SetStateAction<{
        [key: string]: CombinedOfferItem[];
      }>
    >;
    setDayDateSeconds?: Dispatch<SetStateAction<string>>;
    setIsStandard?: Dispatch<SetStateAction<boolean>>;
    setSelectProductOpen?: Dispatch<SetStateAction<boolean>>;
  };
  isSignupFlow?: boolean;
}

export const getWeekProducts = ({
  label,
  isStandard,
  allGroupedItems,
  groupedItems,
  actions,
  isSignupFlow = false,
}: Props) => {
  const {
    setDayDateSeconds,
    setDayProducts,
    setIsStandard,
    setSelectProductOpen,
  } = actions;

  const dateSeconds = Object.entries(allGroupedItems || groupedItems).find(
    ([dateSeconds, _]) => {
      const dayName = moment
        .unix(Number(dateSeconds))
        .format("dddd")
        .toLowerCase();

      const isSameDay = dayName === (label as string).toLowerCase();

      return isSameDay;
    }
  )?.[0];

  if (dateSeconds) {
    setDayProducts((allGroupedItems || groupedItems)[dateSeconds]);
    setDayDateSeconds(dateSeconds);
  }

  if (!isSignupFlow) setIsStandard(isStandard);

  setSelectProductOpen(true);
};
