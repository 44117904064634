import {
  faCircleMinus,
  faCirclePlus,
} from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { OrderInfoFields, OrderingContext } from "@kanpla/ordering";
import { Module } from "@kanpla/types";
import { Button, FormInstance } from "antd";
import { isEmpty } from "lodash";
import { useState } from "react";
import AnimateHeight from "react-animate-height";
import { useTranslation } from "react-i18next";
import { useRefElement } from "rooks";
import { useContainer } from "unstated-next";

interface Props {
  form: FormInstance;
  dateSeconds: number;
  module: Module;
  /** Removes extra height if the component is not expanded */
  noHeightIfExpanded?: boolean;
  noExtraMarginOnSettingsHeader?: boolean;
}

export const OrderInfoItem = (props: Props) => {
  const { basketContainerUtils, defaultReference } =
    useContainer(OrderingContext);
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const { t, i18n } = useTranslation(["mealplan2"]);
  const [ref, element] = useRefElement<HTMLDivElement>();

  const {
    form,
    module,
    dateSeconds,
    noHeightIfExpanded = false,
    noExtraMarginOnSettingsHeader = false,
  } = props;

  const currentBasket = basketContainerUtils.findTargetBasket(
    module.id,
    dateSeconds
  );

  const invoiceReferencePlugin = module?.plugins?.invoiceReference;

  const fieldProps = {
    form: form,
    initialValues: {
      ...(invoiceReferencePlugin?.active &&
        invoiceReferencePlugin?.defaultRequired && {
          invoiceReference: defaultReference,
        }),
      ...Object.entries(currentBasket?.info || {}),
    },
    extraKey: `.--${module.id}.${dateSeconds}--`,
    moduleFromProps: module,
    dateSeconds: dateSeconds,
    orderDocument: currentBasket?.order,
  };

  /**
   * This looks for the amount of inputs in the not required input
   * fields and based on that will show the "open more" button
   */
  const notRequiredFields = element?.children?.item(0)?.[0];

  const hasLanguage = !isEmpty(i18n);

  return (
    <div className="w-full relative">
      <OrderInfoFields
        {...fieldProps}
        filter="required"
        noExtraMarginOnSettingsHeader={noExtraMarginOnSettingsHeader}
      />
      <AnimateHeight
        duration={500}
        height={isExpanded ? "auto" : noHeightIfExpanded ? 0 : 30}
        animateOpacity
      >
        <div ref={ref}>
          <OrderInfoFields
            {...fieldProps}
            filter="notRequired"
            noExtraMarginOnSettingsHeader={noExtraMarginOnSettingsHeader}
          />
        </div>
      </AnimateHeight>

      {notRequiredFields ? (
        <>
          {!isExpanded && (
            <div className="w-full h-16 absolute bottom-8 left-0 flex bg-gradient-to-t from-background-primary" />
          )}
          <div className="py-1 flex justify-center w-full">
            <Button
              type="link"
              onClick={() => setIsExpanded((prevState) => !prevState)}
            >
              {hasLanguage
                ? t("mealplan2:toggle-order-info", {
                    value: isExpanded
                      ? t("mealplan2:values.hide")
                      : t("mealplan2:view"),
                  })
                : `${
                    isExpanded ? "Skjul" : "Vis"
                  } flere order bestillingsinformation`}
              <FontAwesomeIcon
                icon={isExpanded ? faCircleMinus : faCirclePlus}
                className="ml-2"
              />
            </Button>
          </div>
        </>
      ) : null}
    </div>
  );
};
