import { ChildSelector, Selector } from "@kanpla/types";
import { isEmpty } from "lodash";
import { getAllowedOptions } from "../selectors/getAllowedOptions";
import { getChildGroupName } from "./getChildGroupName";

export const validateSelectors = (
  childSelectors: ChildSelector,
  schoolSelectors: Selector[]
) => {
  // If there is no selector on either side, just go ahead and skip it
  if (isEmpty(childSelectors) && isEmpty(schoolSelectors)) return true;

  // If no selector selected but need at least one, return false
  const selectorString = getChildGroupName(childSelectors);
  if (!selectorString) return false;

  const appliedSelectors = (schoolSelectors || []).reduce((acc, selector) => {
    acc[selector?.name] = childSelectors?.[selector?.name];
    return acc;
  }, {});

  const selectorEntries = Object.entries(appliedSelectors || {});
  const allChecks = selectorEntries?.map(([name, value], index) => {
    // is name disabled?
    const isNameDisabled = schoolSelectors
      ?.map((s) =>
        s.options?.map((o) => !isEmpty(value) && o.disabled?.[name] === value)
      )
      .flat()
      .filter((disabled) => disabled);
    if (isNameDisabled?.length) return false;

    // TODO: is name allowed to be empty?
    if (!value) {
      const currentSelector = schoolSelectors?.find((s) => s.name === name);
      const hasAllowedOptions = getAllowedOptions({
        options: currentSelector.options,
        index,
        selectors: childSelectors,
      })?.length;

      if (hasAllowedOptions) return false;
    }

    return true;
  });

  // Return true only if all checks pass
  if (allChecks.every((check) => check)) return true;

  return false;
};
