import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { priceFormatter } from "@kanpla/system";
import { CombinedOfferItem, MenuItem, Supplier } from "@kanpla/types";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import AnimateHeight from "react-animate-height";
import { faChevronDown } from "@fortawesome/pro-duotone-svg-icons";
import classNames from "classnames";
import { Button, Space } from "antd";
import { isEmpty } from "lodash";
import { DisplayAttributes } from "..";

interface Props {
  product: CombinedOfferItem;
  hidePrices: boolean;
  date: string;
  supplier?: Supplier;
}

const Inner = ({ menu, supplier }: { menu: MenuItem; supplier: Supplier }) => {
  const [openInfo, setOpenInfo] = useState(false);
  const { t, i18n } = useTranslation(["translation", "mealplan2"]);

  const hasMenu =
    menu &&
    (menu?.description !== "" ||
      menu?.allergens ||
      menu?.labels ||
      menu?.pictograms);

  if (!hasMenu) return null;

  const infoOnClick = (event) => {
    event.stopPropagation();
    setOpenInfo((open) => !open);
  };

  const seeText = isEmpty(i18n) ? "See" : t("mealplan2:values.see");
  const hideText = isEmpty(i18n) ? "Gem" : t("translation:save");

  return (
    <div>
      <Button
        size="small"
        type="text"
        onClick={(e) => infoOnClick(e)}
        className="p-0"
      >
        <p className="text-sm text-text-secondary opacity-75">
          {openInfo ? hideText : seeText} menu beskrivelse{" "}
          <FontAwesomeIcon
            icon={faChevronDown}
            className={classNames({
              "text-xs transform transition-transform ml-1": true,
              "rotate-180": openInfo,
            })}
          />
        </p>
      </Button>

      <AnimateHeight
        duration={500}
        height={openInfo ? "auto" : 0}
        animateOpacity
        onClick={(e) => infoOnClick(e)}
      >
        <Space
          className="text-sm mt-2 text-text-secondary"
          direction="vertical"
        >
          <p className="whitespace-pre-line">{menu?.description}</p>

          <DisplayAttributes
            attributes={{
              ...menu?.labels,
              ...menu?.allergens,
              ...menu?.pictograms,
            }}
            mode="all"
            supplier={supplier}
          />
        </Space>
      </AnimateHeight>
    </div>
  );
};

export const ProductItemInfo = ({
  product,
  hidePrices,
  date,
  supplier,
}: Props) => {
  const { i18n } = useTranslation(["translation", "libs"]);

  const productPrice = useMemo(() => {
    return priceFormatter(
      product?.price,
      { language: i18n.language },
      false,
      hidePrices,
      {
        wrapper: (price) => {
          return hidePrices ? (
            ""
          ) : (
            <p className="text-primary-main text-xs">{price}</p>
          );
        },
        withPrice: !hidePrices,
      }
    );
  }, [product?.price, hidePrices, i18n.language]);

  const sharedClassNamesAndStyles = useMemo(() => {
    return {
      wrapperStyles: {
        width: "calc(100% - 32px - 32px)",
      },
      wrapperClassNames: "flex flex-col",
    };
  }, []);

  const productNameStyle: React.CSSProperties = useMemo(() => {
    return {
      width: "100%",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
    };
  }, []);

  const menuDescription: MenuItem = product?.dates[date]?.menu;

  return (
    <div
      style={sharedClassNamesAndStyles.wrapperStyles}
      className={sharedClassNamesAndStyles.wrapperClassNames}
    >
      <div className={`${!hidePrices ? "flex flex-col items-center" : ""}`}>
        {menuDescription?.name && (
          <p style={productNameStyle} className="h100">
            {product?.name}
          </p>
        )}
        <p style={productNameStyle} className="font-medium">
          {menuDescription?.name || product?.name}
        </p>
        <Inner menu={menuDescription} supplier={supplier} />
      </div>
      {productPrice}
    </div>
  );
};

export default ProductItemInfo;
