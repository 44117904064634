import { ModalWrapper, SignupCarousel } from "@kanpla/ui";
import { isEmpty } from "lodash";
import router from "next/router";
import React, { useEffect, useState } from "react";
import { useContainer } from "unstated-next";
import { AppContext } from "../contextProvider";
import {
  callFunction,
  isRenderedInWebView,
  webViewVersion,
  db,
  fetchCollection,
} from "@kanpla/system";
import { PopupConstructor, Supplier } from "@kanpla/types";
import { isDesktop, isTablet } from "react-device-detect";

interface Props {
  signupScreens: PopupConstructor.Fullscreen[];
  childId: string;
  schoolId: string;
  pushToUrl: string;
  /** Push to url even if the carousel is not being rendered */
  forcePushToUrl?: boolean;
}

const SignupCarouselWrapper = (props: Props) => {
  const {
    pushToUrl,
    signupScreens,
    childId,
    schoolId,
    forcePushToUrl = false,
  } = props;
  const {
    supplier,
    mobile,
    user,
    partnerId,
    setShouldShowCarousel,
    shouldShowCarousel,
  } = useContainer(AppContext);

  const [open, setOpen] = useState(true);
  const [loading, setLoading] = useState(false);
  const [shouldHideTrackingPreview, setShouldHideTrackingPreview] =
    useState<boolean>(false);

  const isDesktopView = !mobile;

  const [notificationPermissionsReceived, setNotificationPermissionsReceived] =
    useState(false);
  const [trackingPermissionsReceived, setTrackingPermissionsReceived] =
    useState(false);

  const hasAnsweredToNotificationPermissions =
    typeof window !== "undefined" &&
    window?.["hasAnsweredToTrackingPermissions"] !== undefined
      ? JSON.parse(window?.["hasAnsweredToTrackingPermissions"])
      : undefined;

  const hasAnsweredToTrackingPermissions =
    typeof window !== "undefined" &&
    window?.["hasAnsweredToTrackingPermissions"] !== undefined
      ? JSON.parse(window?.["hasAnsweredToTrackingPermissions"])
      : undefined;

  const hideSystemScreens =
    hasAnsweredToTrackingPermissions && hasAnsweredToNotificationPermissions;

  useEffect(() => {
    (async () => await shouldHideTrackingPreviewFetch())();
  }, []);

  const shouldHideTrackingPreviewFetch = async () => {
    try {
      const suppliers = await fetchCollection<Supplier>(
        db.collection("suppliers").where("partnerId", "==", supplier?.partnerId)
      );

      const hasTrackingHidden = suppliers.find(
        (s) => s?.hasTrackingScreenPreviewHidden
      );

      if (hasTrackingHidden) setShouldHideTrackingPreview(true);
    } catch (err) {
      console.error(err);
    }
  };

  const handleEventFromWebView = async (event) => {
    const { data } = event;
    if (typeof data !== "string") return;
    const parsedData = JSON.parse(data);

    if (parsedData?.answeredToNotificationsPermissions) {
      setNotificationPermissionsReceived(true);
      const hasAllowedNotifications =
        parsedData?.hasAllowedNotifications as boolean;

      await callFunction("popups-registerNotificationEvent", {
        childId,
        schoolId,
        partnerId,
        hasAllowedNotifications,
      });
      return;
    }

    if (parsedData?.answeredToTrackingPermissions) {
      setTrackingPermissionsReceived(true);
      return;
    }
  };

  const handleOnLastCustomScreen = async () => {
    try {
      setLoading(true);

      await callFunction("popups-registerPopupEvents", {
        childId,
        schoolId,
        popupIds: signupScreens.map((s) => s.id),
        registeredFrom: isDesktop ? "desktop" : "mobile",
      });
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!isRenderedInWebView) return;
    window.addEventListener("message", handleEventFromWebView);
    return () => window.removeEventListener("message", handleEventFromWebView);
  }, [isRenderedInWebView]);

  if (!shouldShowCarousel) return null;

  if (
    (hideSystemScreens && isEmpty(signupScreens)) ||
    (webViewVersion < 2 && mobile)
  ) {
    forcePushToUrl && router.push(pushToUrl);
    return null;
  }

  if (isDesktopView) {
    return (
      <ModalWrapper
        open={open}
        setOpen={setOpen}
        closableOutside={false}
        containerClassName="md:py-16"
        innerClassName="md:rounded-lg md:shadow-xl relative w-full md:max-w-2xl md:h-full h-auto mx-auto overflow-hidden"
      >
        <SignupCarousel
          screens={signupScreens}
          supplier={supplier}
          onFinish={() => {
            setOpen(false);
            setShouldShowCarousel(false);
            router.push(pushToUrl);
          }}
          onLastCustomScreen={handleOnLastCustomScreen}
          lastButtonLoading={loading}
          hideSystemScreens={
            !isTablet ||
            webViewVersion < 2 ||
            !isRenderedInWebView ||
            hideSystemScreens
          }
          desktopView
          onAllowNotifications={() => {
            window?.["ReactNativeWebView"].postMessage(
              JSON.stringify({ askForNotifications: true })
            );
          }}
          notificationPermissionsReceived={notificationPermissionsReceived}
          onAllowTracking={() => {
            window?.["ReactNativeWebView"].postMessage(
              JSON.stringify({ askForTracking: true })
            );
          }}
          trackingPermissionsReceived={trackingPermissionsReceived}
          showSystemScreensOnly={
            isRenderedInWebView &&
            !hideSystemScreens &&
            (!user?.hasSeenSignupCarousel?.mobile || isEmpty(signupScreens))
          }
        />
      </ModalWrapper>
    );
  }

  return (
    <ModalWrapper
      open={open}
      setOpen={setOpen}
      closableOutside={false}
      fullHeight
      zMax
    >
      <SignupCarousel
        screens={signupScreens}
        supplier={supplier}
        onFinish={() => {
          setShouldShowCarousel(false);
          setOpen(false);
          router.push(pushToUrl);
        }}
        onAllowNotifications={() => {
          window?.["ReactNativeWebView"].postMessage(
            JSON.stringify({ askForNotifications: true })
          );
        }}
        notificationPermissionsReceived={notificationPermissionsReceived}
        onAllowTracking={() => {
          window?.["ReactNativeWebView"].postMessage(
            JSON.stringify({ askForTracking: true })
          );
        }}
        trackingPermissionsReceived={trackingPermissionsReceived}
        onLastCustomScreen={handleOnLastCustomScreen}
        lastButtonLoading={loading}
        hideSystemScreens={
          webViewVersion < 2 || !isRenderedInWebView || hideSystemScreens
        }
        showSystemScreensOnly={
          isRenderedInWebView &&
          !hasAnsweredToNotificationPermissions &&
          (!user?.hasSeenSignupCarousel?.mobile || isEmpty(signupScreens))
        }
        hidePreviewOnTrackingScreen={shouldHideTrackingPreview}
      />
    </ModalWrapper>
  );
};

export default SignupCarouselWrapper;
