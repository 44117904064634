import {
  CombinedOfferItem,
  FlexBulkStandard,
  GroupedCombinedOffer,
  OrderOrderProduct,
  Product,
} from "@kanpla/types";
import React from "react";
import { Cell } from "./Cell";

interface Props {
  products: GroupedCombinedOffer | CombinedOfferItem[] | Product[];
  days: string[];
  standard: FlexBulkStandard["standard"];
  updateStandard: ({
    amount,
    productId,
    dayIndex,
  }: {
    amount: number;
    productId: string;
    dayIndex: number;
  }) => void;
}

export const BulkContent = ({
  products,
  days,
  standard,
  updateStandard,
}: Props) => {
  return (
    <div className="-mx-8 overflow-x-auto lg:mx-0">
      <table className="table text-left w-full">
        <thead>
          <tr>
            <th className="hidden md:table-cell"></th>
            {days.map((label, dayIndex) => (
              <th key={dayIndex}>{label}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {products.map((product) => (
            <React.Fragment key={product.id}>
              <tr className="md:hidden separator">
                <td colSpan="100%">{product.name}</td>
              </tr>
              <tr>
                <th className="hidden md:table-cell	">{product.name}</th>
                {days.map((label, dayIndex) => (
                  <Cell
                    key={label}
                    amount={standard?.[dayIndex]?.[product.id]?.amount || 0}
                    setAmount={(amount) => {
                      updateStandard({
                        amount,
                        dayIndex,
                        productId: product.id,
                      });
                    }}
                  />
                ))}
              </tr>
            </React.Fragment>
          ))}

          <tr className="md:hidden separator">
            <td colSpan="100%">I alt:</td>
          </tr>
          <tr className="totals">
            <th className="hidden md:table-cell">i alt:</th>
            {days.map((label, dayIndex) => (
              <td key={dayIndex} className="bg-background-secondary">
                <div className="text-center font-semibold">
                  {Object.values(standard?.[dayIndex] || {}).reduce(
                    (a: number, c: OrderOrderProduct) => a + c.amount,
                    0
                  )}
                </div>
              </td>
            ))}
          </tr>
        </tbody>
      </table>
    </div>
  );
};
