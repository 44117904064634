import {
  CombinedOffer,
  CombinedOfferItem,
  FlexBulkStandard,
  GroupedCombinedOffer,
  IBaseProducts,
  IGetCurrentProductsProps,
  Product,
  Week,
} from "@kanpla/types";
import React, { Dispatch, SetStateAction } from "react";
import { Day } from "../Day";

interface Props {
  products: GroupedCombinedOffer | CombinedOfferItem[] | Product[];
  days: string[];
  standard: FlexBulkStandard["standard"];
  initialStandard: FlexBulkStandard["standard"];
  updateStandard: ({
    amount,
    productId,
    dayIndex,
  }: {
    amount: number;
    productId: string;
    dayIndex: number;
  }) => void;
  fromAdmin: boolean;
  week: Week;
  plainProducts: CombinedOffer;
  getCurrentProducts: (props: IGetCurrentProductsProps) => void;
  selectedProducts: IBaseProducts;
  setCurrentDayIndex: Dispatch<SetStateAction<number>>;
  currentDayIndex: number;
}

export const IndividualContent = ({
  days,
  products,
  standard,
  initialStandard,
  updateStandard,
  selectedProducts,
  getCurrentProducts,
  plainProducts,
  week,
  currentDayIndex,
  setCurrentDayIndex,
  fromAdmin,
}: Props) => {
  return (
    <div className="pt-1">
      {days.map((label, dayIndex) => (
        <Day
          label={label}
          products={products}
          key={dayIndex.toString()}
          value={standard?.[dayIndex] || initialStandard?.[dayIndex]}
          setActive={(productId) =>
            updateStandard({ amount: 1, dayIndex, productId })
          }
          selectedProducts={selectedProducts}
          getCurrentProducts={getCurrentProducts}
          plainProducts={plainProducts}
          daySeconds={week?.[dayIndex]?.seconds}
          currentDayIndex={currentDayIndex}
          setCurrentDayIndex={setCurrentDayIndex}
          dayIndex={dayIndex}
          fromAdmin={fromAdmin}
        />
      ))}
    </div>
  );
};
