import { OrderInfo as OrderInfoType, OrderOrder } from "@kanpla/types";
import { FormInstance } from "antd/lib/form/Form";
import { OrderInfoFields } from "./OrderInfoFields";

interface Props {
  basket: OrderOrder;
  orderInfo: OrderInfoType;
  form: FormInstance<any>;
}

export const OrderInfo = (props: Props) => {
  const { basket, orderInfo, form } = props;
  return (
    <OrderInfoFields
      form={form}
      initialValues={orderInfo}
      orderDocument={basket || {}}
    />
  );
};
