import React, { useEffect, useState } from "react";
import { useDebouncedValue } from "rooks";

interface Props {
  setAmount: (amount: number) => void;
  amount: number;
}

export const Cell = ({ setAmount, amount }: Props) => {
  const [value, setValue] = useState(amount);
  const [debouncedValue] = useDebouncedValue<number>(value, 700);

  useEffect(() => {
    if (debouncedValue === amount) return;
    if (isNaN(debouncedValue) || debouncedValue < 0) {
      setAmount(0);
      return;
    }
    setAmount(debouncedValue);
  }, [debouncedValue]);

  return (
    <td style={{ padding: 0 }}>
      <input
        type="number"
        value={value}
        className="px-1 py-4 w-full h-full text-center focus:outline-none focus:font-semibold text-text-secondary focus:text-text-primary"
        onChange={(e) => {
          const newAmount = parseInt(e.target.value);
          setValue(newAmount);
        }}
      />
    </td>
  );
};
