import React from "react";
import { FavoriteProductsPreview } from "../../elements/communication/ProductsPreview";
import { SystemScreen } from "./SystemScreen";

interface Props {
  onContinue?: () => void;
  hidePreview?: boolean;
}

export const TrackingScreen = (props: Props) => {
  const { onContinue = () => true, hidePreview = false } = props;

  return (
    <SystemScreen
      title="Tillad Tracking"
      description="Hjælp os med at skabe den bedste oplevelse til dig"
      previewElement={
        hidePreview ? <div className="w-full" /> : <FavoriteProductsPreview />
      }
      backgroundImageUrl="https://firebasestorage.googleapis.com/v0/b/kanpla-87be3.appspot.com/o/s-o-c-i-a-l-c-u-t-32kl7FPz2Gc-unsplash%20(1).jpg?alt=media&token=1b713d71-3f89-4b82-97ff-14cd34393f37"
      onContinue={onContinue}
    />
  );
};
