import React, { useState, useEffect } from "react";
import { message } from "antd";
import classnames from "classnames";
import { faMinus, faPlus } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isEmpty } from "lodash";
import { useTranslation } from "react-i18next";

interface Props {
  amount: number; // state value
  setAmount: any; // function to setState
  disabled?: boolean;
  minAmount?: number;
  maxAmount?: number;
  className?: string;
  size?: "small" | "medium";
  hapticCallback?: () => void;
  stopPropagation?: boolean;
}

export const InputAmount = (props: Props) => {
  const {
    amount,
    setAmount,
    disabled = false,
    minAmount = 0,
    maxAmount = Infinity,
    className,
    size = "medium",
    hapticCallback = () => null,
    stopPropagation = false,
  } = props; // amount, always a valid number
  const { t, i18n } = useTranslation(["libs"]);
  const [value, setValue] = useState(amount); // input field value, can be NaN

  useEffect(() => setValue(amount), [amount]);

  const updateAmount = (change: number) => {
    const oldAmount = isNaN(amount) ? 0 : amount;
    let newAmount = oldAmount + change;
    if (newAmount < minAmount) newAmount = minAmount;
    if (newAmount > maxAmount) newAmount = maxAmount;
    setAmount(newAmount);
  };

  const buttonStyles = classnames([
    size === "small" ? "px-2" : "px-4",
    "text-secondary-contrast h-full disabled:text-secondary-light hover:bg-secondary-light disabled:hover:bg-transparent transform m-0",
  ]);

  const minDisabled = disabled || amount === (minAmount || 0);
  const maxDisabled = disabled || amount === maxAmount;
  const height = size === "small" ? 30 : 40;

  const inputClasses = classnames({
    "text-primary-main text-lg py-2 font-medium rounded text-center h-full mx-1":
      true,
    "w-12": size === "medium",
    "w-10": size === "small",
  });

  return (
    <div
      style={{ height }}
      className={`border-secondary-main border-4 box-content inline-flex items-center rounded-lg bg-secondary-main ${className}`}
    >
      <button
        className={`rounded-md transition-colors ${buttonStyles}
         ${minDisabled ? "text-text-disabled cursor-default" : ""}
        `}
        onClick={(e) => {
          if (stopPropagation) {
            e.stopPropagation();
          }
          hapticCallback();
          updateAmount(-1);
        }}
        disabled={minDisabled}
      >
        <FontAwesomeIcon icon={faMinus} />
      </button>
      <input
        disabled={disabled}
        className={inputClasses}
        type="number"
        pattern="[0-9]*"
        inputMode="numeric"
        min={minAmount}
        value={value}
        max={maxAmount}
        onFocus={(e) => e.currentTarget.select()}
        onClick={(e) => {
          if (stopPropagation) {
            e.stopPropagation();
          }
        }}
        onChange={(e) => {
          if (stopPropagation) {
            e.stopPropagation();
          }

          const newAmount = parseInt(e.target.value);
          if (newAmount > maxAmount) {
            setValue(maxAmount);
            setAmount(maxAmount);
            message.error(
              isEmpty(i18n)
                ? `Der er kun ${maxAmount} produkter tilbage`
                : t("libs:message.error.products-left", { value: maxAmount })
            );
            return;
          }
          setValue(newAmount);
          if (isNaN(newAmount)) return;
          setAmount(newAmount);
        }}
        onBlur={() => {
          setValue(amount);
        }}
      />
      <button
        onClick={(e) => {
          if (stopPropagation) {
            e.stopPropagation();
          }

          hapticCallback();
          updateAmount(1);
        }}
        className={`rounded-md transition-colors ${buttonStyles}
         ${maxDisabled ? "text-main-400 cursor-default" : ""}
        `}
        disabled={disabled || amount === (maxAmount || Infinity)}
      >
        <FontAwesomeIcon icon={faPlus} />
      </button>
    </div>
  );
};
