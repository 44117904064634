import { faMailbox } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { db, fn } from "@kanpla/system";
import { AnonymousPropsFlow, EmailVerificationState } from "@kanpla/types";
import { Button, message } from "antd";
import dynamic from "next/dynamic";
import { useEffect, useState } from "react";
import { useDocument } from "react-firebase-hooks/firestore";
import { Trans, useTranslation } from "react-i18next";
import { useContainer } from "unstated-next";
import { SignUpFlowContext } from "..";
import { AppContext } from "../../contextProvider";
import PageWrapper from "../PageWrapper";

const ReactCodeInput = dynamic(() =>
  import("react-code-input").catch((err) => {
    return () => <p>Failed to load</p>;
  })
);

const EmailCode = ({
  isFromAnonymousFlow = false,
  isFromAPrivateModule = false,
}: AnonymousPropsFlow) => {
  const { t } = useTranslation(["signup-flow", "translation"]);
  const { customBranding } = useContainer(AppContext);
  const { email, setCurrentPage } = useContainer(SignUpFlowContext);
  const [code, setCode] = useState<string>("");
  const [sendAgainLoading, setSendAgainLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isVerified, setIsVerified] = useState(false);

  const [verifiedDoc] = useDocument<EmailVerificationState>(
    db.collection("emailVerificationStates").doc(email),
    { snapshotListenOptions: { includeMetadataChanges: true } }
  );

  useEffect(() => {
    if (!verifiedDoc) return;

    setIsVerified(
      verifiedDoc?.metadata?.fromCache === false &&
        verifiedDoc?.metadata?.hasPendingWrites === false &&
        verifiedDoc?.data()?.verified === true
    );
  }, [verifiedDoc]);

  useEffect(() => {
    if (isVerified) setCurrentPage("user");
  }, [isVerified]);

  const submit = async () => {
    try {
      setLoading(true);
      const codeString = `${code}`;

      await db.collection("emailVerificationStates").doc(email).set({
        email,
        code: codeString,
        verified: true,
      });

      message.success(t("signup-flow:message.success.code-correct"));
    } catch (err) {
      console.log("🚀 ~ file: EmailCode.tsx ~ line 44 ~ submit ~ err", err);
      message.error(t("signup-flow:message.error.wrong-code"));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (code.length < 4) return;
    submit();
  }, [code]);

  const sendCodeAgain = async () => {
    try {
      setSendAgainLoading(true);
      const callable = fn.httpsCallable("signup-validateEmail");
      await callable({
        email,
        onlyCompanySignup: true,
        partnerId: customBranding?.partnerId || null,
      });

      message.success(t("signup-flow:message.success.code-sent-again"));
    } catch (err) {
      message.error(err.message);
    } finally {
      setSendAgainLoading(false);
    }
  };

  const SendAgainComponent = () => {
    return (
      <div className="mt-3 text-center pt-4">
        {t("signup-flow:no-code-question")}
        <span className="text-primary-main hover:text-primary-dark">
          <Button
            type="link"
            className="-mx-3"
            onClick={sendCodeAgain}
            htmlType="button"
            loading={sendAgainLoading}
          >
            <a className="font-semibold">{t("signup-flow:resent-the-code")}</a>
          </Button>
        </span>
      </div>
    );
  };

  return (
    <PageWrapper
      title={t("signup-flow:confirm-email-address")}
      subtitle={
        <p className="text-center leading-6">
          <Trans t={t} i18nKey="signup-flow:email-has-been-sent">
            Der er sendt en mail til
            <span className="text-primary-main font-semibold">
              {{ value: email }}
            </span>
          </Trans>
        </p>
      }
      buttonTitle={t("translation:confirm")}
      onButtonSubmit={submit}
      extraContent={<SendAgainComponent />}
      showBackButton={true}
      stayOnPageAfterSubmit={true}
      buttonLoading={loading}
      isFromAnonymousFlow={isFromAnonymousFlow}
      isFromAPrivateModule={isFromAPrivateModule}
    >
      <p className="text-center leading-6 pt-2">
        {t("signup-flow:enter-the-4-digit")}
      </p>
      <div className="pb-2 pt-6 flex justify-center">
        <ReactCodeInput
          type="number"
          className="w-full grid grid-cols-4"
          inputStyle={{
            fontFamily: "inherit",
            padding: 12,
            border: "1px solid",
            borderRadius: 6,
            margin: 6,
            textAlign: "center",
            fontSize: 22,
            fontWeight: "600",
            borderColor: "var(--palette-primary-main)",
          }}
          style={{
            display: "grid !important",
          }}
          value={code}
          onChange={(newValue) => {
            setCode(newValue);
            // if (newValue.length === 4) setTimeout(() => submit(), 200);
          }}
          fields={4}
          name="emailCode"
          inputMode="numeric"
        />
      </div>
      <div className="mt-4 bg-background-secondary border rounded-lg grid grid-cols-8 p-3 px-4">
        <FontAwesomeIcon
          icon={faMailbox}
          className="text-xl text-primary-main mt-1"
        />
        <div className="col-span-7 text-text-secondary text-sm">
          {t("signup-flow:take-up-2-minutes")}
        </div>
      </div>
    </PageWrapper>
  );
};

export default EmailCode;
