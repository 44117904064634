import { faTruckClock } from "@fortawesome/pro-duotone-svg-icons";
import { getActivePlugins, isDefaultReferenceValid } from "@kanpla/system";
import {
  CombinedOfferItem,
  Module,
  OrderOrder,
  OrderPersonal,
  Plugins,
} from "@kanpla/types";
import {
  InvoiceReference,
  ProductSettingsHeader,
  RequiredProduct,
  TextInput,
  TimeSelect,
} from "@kanpla/ui";
import {
  Button,
  DatePicker,
  Form,
  FormInstance,
  Input,
  InputNumber,
  Space,
} from "antd";
import classNames from "classnames";
import { isEmpty } from "lodash";
import moment from "moment";
import { Dispatch, SetStateAction, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useContainer } from "unstated-next";
import { OrderingContext } from "../../context";

interface DefaultProps {
  withIndividualTimeEdit?: boolean;
  setEditMode?: Dispatch<SetStateAction<boolean>>;
  form: FormInstance<any>;
  initialValues: any;
  moduleFromProps?: Module;
  extraKey?: string;
  onChange?: (
    newData: OrderPersonal["info"],
    moduleId: string,
    dateSeconds: number
  ) => void;
  dateSeconds?: number;
  orderDocument: OrderOrder;
  /**
   * For meeting, this has to be passed through props, since it's in a different context
   */
  hasRequiredProduct?: boolean;
  /**
   * For better separation, you can send in how you want it filtered...
   * If it's empty, it won't filter
   */
  filter?: "required" | "notRequired";
  noExtraMarginOnSettingsHeader?: boolean;
  items?: Array<CombinedOfferItem>;
}

interface PropsWithIndividualTimeEdit extends DefaultProps {
  withIndividualTimeEdit: true;
  setEditMode: Dispatch<SetStateAction<boolean>>;
}

type Props = PropsWithIndividualTimeEdit | DefaultProps;

export const OrderInfoFields = ({
  withIndividualTimeEdit = false,
  setEditMode,
  form,
  initialValues = {},
  moduleFromProps,
  extraKey = "",
  orderDocument,
  filter,
  noExtraMarginOnSettingsHeader = false,
  hasRequiredProduct: hasRequiredProductProps,
  items: itemsProps,
}: Props) => {
  const { t, i18n } = useTranslation(["mealplan2", "libs"]);

  const hasLanguage = !isEmpty(i18n);

  /**
   * Util to change the localization of moment.js
   * dow and doy explanation: https://github.com/moment/momentjs.com/issues/279
   */
  moment.updateLocale(i18n.language || "da", { week: { dow: 1, doy: 4 } });

  const {
    defaultReference,
    schoolId,
    child,
    week,
    dayIndex,
    activePlugins: activePluginsFromContext,
    basket,
    setBasket,
    offer,
    module: moduleFromContext,
    hasRequiredProduct: hasRequiredProductContext,
  } = useContainer(OrderingContext);
  const itemsContext = offer?.items || [];
  const mealOptions = offer?.mealOptions || [];

  const hasRequiredProduct =
    hasRequiredProductProps || hasRequiredProductContext;

  const items = itemsProps || itemsContext;

  const module = moduleFromProps || moduleFromContext;

  const activePlugins = useMemo(
    () =>
      moduleFromProps ? getActivePlugins({ module }) : activePluginsFromContext,
    [activePluginsFromContext, module, moduleFromProps]
  );
  const plugins = module?.plugins || ({} as Plugins.Array);

  const shouldHideReference =
    plugins?.invoiceReference?.hideInBasket &&
    isDefaultReferenceValid({ child, module });

  return (
    <Form
      className="mt-4"
      layout="vertical"
      form={form}
      initialValues={{
        ...initialValues,
        [`timeInput${extraKey}`]:
          activePlugins?.["timeInput"] &&
          (initialValues?.timeInput || plugins?.timeInput?.startAt || 27000),
        [`reference${extraKey}`]:
          activePlugins?.["invoiceReference"] &&
          (initialValues?.reference || defaultReference),
      }}
    >
      {module?.flow === "meeting" && (!filter || filter === "required") && (
        <Space align="baseline">
          <div>
            <ProductSettingsHeader
              title={hasLanguage ? t("mealplan2:name-meeting") : "Navn på møde"}
              noBg
              noExtraMargin={noExtraMarginOnSettingsHeader}
            />
            <Form.Item name={`name${extraKey}`}>
              <Input
                className="mt-1"
                placeholder={
                  hasLanguage ? t("mealplan2:name-meeting") : "Navn på møde"
                }
              />
            </Form.Item>
          </div>
          <div>
            <ProductSettingsHeader
              title={hasLanguage ? t("mealplan2:meeting-date") : "Mødedato"}
              noBg
              noExtraMargin={noExtraMarginOnSettingsHeader}
            />
            <DatePicker
              className="mt-1"
              placeholder={
                hasLanguage ? t("mealplan2:meeting-date") : "Mødedato"
              }
              disabled={true}
              allowClear={false}
              value={moment(week[dayIndex].toDate())}
              format={(m) => m.format("ll")}
              name={`date${extraKey}`}
            />
          </div>
        </Space>
      )}
      {activePlugins["timeInput"] && (!filter || filter === "required") && (
        <div>
          <Form.Item
            name={`timeInput${extraKey}`}
            label={
              <ProductSettingsHeader
                className="w-full"
                title={
                  plugins?.timeInput?.fieldName || hasLanguage
                    ? t("libs:delivery-time")
                    : "Leveringstidspunkt"
                }
                subtitle={
                  plugins?.timeInput?.fieldDescription || hasLanguage
                    ? t("libs:desired-delivery-time")
                    : "Dit køkken giver dig muligheden for at vælge dit ønskede leveringstidspunkt."
                }
                icon={faTruckClock}
                noBg
                noExtraMargin={noExtraMarginOnSettingsHeader}
              />
            }
            rules={[
              {
                required: true,
                type: "number",
                message: isEmpty(i18n)
                  ? "Dette felt er påkrævet"
                  : hasLanguage
                  ? t("libs:specific-field-required", {
                      fieldName: t("libs:delivery-time"),
                    })
                  : "Leveringstidspunkt er påkrævet",
              },
            ]}
          >
            {/* @ts-ignore */}
            <TimeSelect
              interval={plugins?.timeInput?.interval}
              endAt={plugins?.timeInput?.endAt}
              startAt={plugins?.timeInput?.startAt}
            />
          </Form.Item>
          {withIndividualTimeEdit && setEditMode && (
            <Button
              onClick={() => setEditMode((mode) => !mode)}
              className="whitespace-normal h-auto mb-4"
            >
              {hasLanguage
                ? t("mealplan2:edit-delivery-time-for-each-item")
                : "Rediger leveringstidspunkt for hvert produkt"}
            </Button>
          )}
        </div>
      )}
      {activePlugins["invoiceReference"] && (!filter || filter === "required") && (
        <div
          className={classNames({
            "absolute h-0 w-0 opacity-0 pointer-events-none hidden":
              shouldHideReference,
          })}
        >
          <InvoiceReference
            noBg
            module={module}
            defaultReference={defaultReference}
            // Require the reference by default
            required={module?.plugins?.invoiceReference?.required ?? true}
            formItemName={`reference${extraKey}`}
            noExtraMargin={noExtraMarginOnSettingsHeader}
          />
        </div>
      )}

      {activePlugins["textInput"] && (
        <TextInput
          noBg
          orderInfo={initialValues}
          settings={plugins?.textInput}
          schoolId={schoolId}
          groupName={child?.groupName}
          extraKey={extraKey}
          orderDocument={orderDocument}
          filter={filter}
        />
      )}

      {hasRequiredProduct && (!filter || filter === "required") && (
        <RequiredProduct
          settings={plugins?.requiredProduct}
          requiredProductOrder={basket}
          setRequiredProductOrder={(requiredOrder) =>
            setBasket({ ...basket, ...requiredOrder })
          }
          mealOptions={mealOptions}
          module={module}
          order={basket}
          offerItems={items}
        />
      )}

      {activePlugins["orderForAmountOfPeople"] &&
        (!filter || filter === "required") && (
          <div className="mx-2">
            <ProductSettingsHeader
              title={
                hasLanguage ? t("mealplan2:number-of-people") : "Antal personer"
              }
              noBg
              noExtraMargin={noExtraMarginOnSettingsHeader}
            />
            <Form.Item
              name={`numberOfPeople${extraKey}`}
              rules={[{ type: "number" }]}
            >
              <InputNumber
                pattern="[0-9]*"
                inputMode="numeric"
                required={true}
              />
            </Form.Item>
          </div>
        )}
    </Form>
  );
};
