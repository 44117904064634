import { getWeekDays } from "@kanpla/system";
import {
  CombinedOffer,
  CombinedOfferItem,
  FlexBulkStandard,
  GroupedCombinedOffer,
  IBaseProducts,
  IGetCurrentProductsProps,
  ISubmitStandardProps,
  Product,
  Week,
} from "@kanpla/types";
import { message } from "antd";
import { cloneDeep, isEmpty, set, unset } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { DrawerOrModal } from "../elements/BottomDrawer/DrawerOrModal";
import { BulkContent } from "./bulk/BulkContent";
import { IndividualContent } from "./individual/IndividualContent";

interface Props {
  /** State of the modal */
  open: boolean;
  /** Open/close the modal */
  setOpen: (newState: boolean) => void;
  /** All the products available for the standard choice */
  products: CombinedOfferItem[] | GroupedCombinedOffer | Product[];
  /** The current standard choice */
  standard: FlexBulkStandard["standard"];
  /** Target school */
  schoolId: string;
  /** Target school */
  moduleId: string;
  /** Current child, also used as a target for individual */
  childId: string;
  userId: string;
  /** Functions provider */
  fn: any;
  /** Individual or bulk */
  isBulk?: boolean;
  /** The edit is made by the admin */
  fromAdmin?: boolean;
  callback?: () => void;
  selectedProducts?: IBaseProducts;
  selectStandardOrderOpen?: boolean;
  getCurrentProducts?: (props: IGetCurrentProductsProps) => void;
  plainProducts?: CombinedOffer;
  submitStandard?: (props: ISubmitStandardProps) => Promise<void>;
  week?: Week;
  standardWeek?: FlexBulkStandard["standard"];
  /** Define if the standard order comes from the signup flow */
  isSignupFlow?: boolean;
}

export const EditStandardOrder = (props: Props) => {
  const {
    open,
    setOpen,
    products,
    standard: initialStandard,
    schoolId,
    fn,
    moduleId,
    childId,
    userId,
    isBulk,
    fromAdmin,
    selectedProducts,
    selectStandardOrderOpen,
    getCurrentProducts,
    plainProducts,
    submitStandard,
    week,
    standardWeek,
    callback,
    isSignupFlow = false,
  } = props;

  const { t, i18n } = useTranslation(["translation", "libs", "flex"]);

  const days = useMemo(() => {
    const days = getWeekDays(isEmpty(i18n) ? "dk" : i18n.language);
    return days;
  }, [JSON.stringify(i18n)]);

  const [loading, setLoading] = useState(false);
  const [currentDayIndex, setCurrentDayIndex] = useState(-1);
  const [standard, setStandard] =
    useState<FlexBulkStandard["standard"]>(initialStandard);

  useEffect(() => {
    if (!selectStandardOrderOpen) setCurrentDayIndex(-1);
  }, [selectStandardOrderOpen]);

  // Load initial value
  useEffect(() => {
    if (isEmpty(standard) && !isEmpty(initialStandard))
      setStandard(initialStandard);
  }, [initialStandard]);

  // Update values
  const updateStandard = ({ amount, productId, dayIndex }) => {
    setStandard((oldValues) => {
      const newStandard = cloneDeep({
        ...(oldValues || {}),
      } as FlexBulkStandard["standard"]);

      if (!isBulk) unset(newStandard, `${dayIndex}`);

      if (productId) set(newStandard, `${dayIndex}.${productId}`, { amount });

      return newStandard;
    });
  };

  // Submit values to db
  const submit = async () => {
    try {
      message.loading(
        isEmpty(i18n)
          ? "Standarder gemmes"
          : t("translation:message.loading.standards-are-saved"),
        0
      );

      // Delete empty lunches before submitting
      delete standard?.[0]?.no_lunch;
      delete standard?.[1]?.no_lunch;
      delete standard?.[2]?.no_lunch;
      delete standard?.[3]?.no_lunch;
      delete standard?.[4]?.no_lunch;

      const submitToServer = fn.httpsCallable("submitFlexStandard");

      await submitToServer({
        standard,
        schoolId,
        moduleId,
        userId,
        childId,
        fromAdmin,
      });

      message.destroy();
      message.success(
        isEmpty(i18n)
          ? "Standarder opdateret."
          : t("translation:message.success.standards-updated")
      );

      callback();
    } catch (err) {
      console.error(err);
      message.error(
        isEmpty(i18n)
          ? "Ændringer kunne ikke gemmes."
          : t("translation:message.error.changes-could-not-be-saved")
      );
    } finally {
      setOpen(false);
    }
  };

  const title = isBulk
    ? isEmpty(i18n)
      ? "Angiv standardbestilling"
      : t("libs:set-default-order")
    : isEmpty(i18n)
    ? "Angiv standardvalg"
    : t("translation:edit-default-selection");

  const subtitle = isBulk
    ? isEmpty(i18n)
      ? "Rediger din virksomheds standardbestilling for de forskellige dage"
      : t("libs:edit-company-order")
    : isEmpty(i18n)
    ? "Rediger dit standardvalg for de forskellige dage"
    : t("flex:default-selection-description");

  const actions = useMemo(() => {
    const onClickProp = fromAdmin
      ? {
          onClick: async () => {
            await submit();
          },
        }
      : {
          onClick: async () => {
            if (!isSignupFlow) {
              setLoading(true);
              await submitStandard({ fromAdmin });
              setLoading(false);
              return;
            }
            setOpen(false);
          },
        };

    const actions = [
      {
        label: isEmpty(i18n) ? "Bekræft" : t("translation:confirm"),
        ...onClickProp,
        loading,
        className: "primary",
      },
    ];

    return actions;
  }, [fromAdmin, JSON.stringify(standard), JSON.stringify(standardWeek)]);

  return (
    <DrawerOrModal
      open={open}
      setOpen={setOpen}
      title={title}
      subtitle={subtitle}
      actions={actions}
      className="max-w-xl"
    >
      {isBulk && !isSignupFlow ? (
        <BulkContent
          products={products}
          days={days}
          standard={standard}
          updateStandard={updateStandard}
        />
      ) : (
        <IndividualContent
          days={days}
          standard={standard}
          initialStandard={initialStandard}
          products={products}
          updateStandard={updateStandard}
          fromAdmin={fromAdmin}
          currentDayIndex={currentDayIndex}
          setCurrentDayIndex={setCurrentDayIndex}
          selectedProducts={selectedProducts}
          getCurrentProducts={getCurrentProducts}
          plainProducts={plainProducts}
          week={week}
        />
      )}
    </DrawerOrModal>
  );
};
