import { faIntercom } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useIntercom } from "react-use-intercom";
import CompanyInfo from "./CompanyInfo";

const Helpcenter = () => {
  const { t } = useTranslation(["components", "translation"]);

  const [infoOpen, setInfoOpen] = useState(false);

  return (
    <div className="w-full flex flex-col justify-center overflow-y-hidden relative pt-20 pb-16 mt-2">
      <h2 className="h700 leading-tight mb-4 text-center text-text-primary">
        {t("components:technical-questions")}
      </h2>
      <a
        href="https://intercom-help.eu/kanpla/da/"
        target="_blank"
        className="text-center text-lg font-medium text-primary-main hover:text-primary-dark py-2"
        rel="noreferrer"
      >
        {t("components:visit-our-help-center")}
      </a>

      <Helpcenter.Intercom />

      <button
        className="text-center text-lg font-medium text-primary-main hover:text-primary-dark py-2"
        onClick={() => setInfoOpen(true)}
      >
        {t("components:contact-info")}
      </button>

      {/* Info Modal */}
      <CompanyInfo open={infoOpen} setOpen={setInfoOpen} />
    </div>
  );
};

Helpcenter.Intercom = ({ small }) => {
  const { t } = useTranslation(["translation"]);
  const { show } = useIntercom();

  return (
    <button
      onClick={show}
      className={`text-center font-medium text-primary-main hover:text-primary-dark py-2 ${
        small ? "text-sm" : "text-lg"
      }`}
    >
      <FontAwesomeIcon icon={faIntercom} /> {t("translation:chat-with-us")}
    </button>
  );
};

export default Helpcenter;
