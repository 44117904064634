import moment from "moment";

export const getWeekDays = (locale: string) => {
  const indexDays = [0, 1, 2, 3, 4];
  moment.locale(locale, {
    week: {
      dow: 1,
    },
  });
  return moment.weekdays(true).filter((_, index) => indexDays.includes(index));
};
