import { Module, School } from "@kanpla/types";
import { isEmpty } from "lodash";

interface Props {
  /** All the school's modules */
  modules: Module[];
  /** The target school */
  school: School;
  /** The target moduleId */
  moduleId?: string;
}

/**
 * Checks if a module has enabled standard ordering, given module and school
 * @interface props
 * @returns `true` if the module should have access to standard ordering
 */
export const shouldHaveStandardOrdering = (props: Props) => {
  const { modules, moduleId, school } = props;

  if (isEmpty(modules)) return true;

  let hasStandardOrderDisabledOnModule: boolean = null;

  const targetModule = moduleId && modules.find((m) => m.id === moduleId);

  if (targetModule) {
    const { config } = targetModule;

    hasStandardOrderDisabledOnModule = config?.standardOrderingDisabled;
  } else {
    hasStandardOrderDisabledOnModule = Boolean(
      modules.some((m) => m?.config?.standardOrderingDisabled)
    );
  }

  // Disabled on the school
  const { modules: schoolModules } = school;
  const standardOrderingOnSchool =
    schoolModules?.[module.id]?.standardOrderingDisabled;

  const standardOrderOnModuleNotSpecified =
    typeof standardOrderingOnSchool === "undefined" ||
    typeof standardOrderingOnSchool === null;

  if (
    hasStandardOrderDisabledOnModule &&
    (standardOrderingOnSchool || standardOrderOnModuleNotSpecified)
  )
    return false;

  if (!hasStandardOrderDisabledOnModule && standardOrderingOnSchool)
    return false;

  return true;
};
