import { getWeekSeconds } from "@kanpla/system";
import { CombinedOfferItem, GroupedCombinedOffer, Week } from "@kanpla/types";

interface Props {
  items: CombinedOfferItem[];
  week: Week;
}

/**
 * This method group the offers by available day and by category.
 * Object structure:
 * {
 *   [dateSeconds]: {
 *     [category]: [offer, offer, offer],
 *     [category]: [offer, offer],
 *   },
 *   ...
 * }
 */
export const groupAllItems = ({ items, week }: Props): GroupedCombinedOffer => {
  const thisMondaySeconds = getWeekSeconds(new Date().getTime() / 1000);

  const datesSeconds = getGroup({
    items: week as any,
    entity: "dates",
    thisMondaySeconds,
  });
  const categories = getGroup({ items, entity: "category" });

  const groupedItems = datesSeconds.reduce((acc, dateSeconds) => {
    const reducedCategories = categories.reduce(
      (acc, category) => ({ ...acc, [category]: [] }),
      {}
    );

    return {
      ...acc,
      [dateSeconds]: reducedCategories,
    };
  }, {});

  Object.entries(groupedItems).forEach(([_, value]) => {
    Object.keys(value).forEach((category) => {
      const filteredItems = items.filter((item) => item.category === category);

      value[category] = filteredItems;
    });
  });

  return groupedItems;
};

interface GetGroupProps {
  items: CombinedOfferItem[];
  entity: "dates" | "category";
  thisMondaySeconds?: number;
}

export const getGroup = ({ items, entity }: GetGroupProps): string[] => {
  const values = items?.reduce((acc, item) => {
    switch (entity) {
      case "dates": {
        return [...acc, item["seconds"]];
      }
      case "category":
        return [...acc, item[entity]];
      default:
        return [...acc, item];
    }
  }, []);

  const uniqueValues = [...new Set(values)];

  return uniqueValues;
};
