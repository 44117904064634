import { fn } from "@kanpla/system";
import { Module } from "@kanpla/types";
import {
  EditStandardOrder,
  SelectProductModal,
  VariantsModal,
} from "@kanpla/ui";
import { Dispatch, SetStateAction, useMemo } from "react";
import { useContainer } from "unstated-next";
import { SignUpFlowContext } from "..";
import { AppContext } from "../../contextProvider";

interface Props {
  openStandardOrder: boolean;
  setOpenStandardOrder: Dispatch<SetStateAction<boolean>>;
  openVariants: boolean;
  setOpenVariants: Dispatch<SetStateAction<boolean>>;
  module: Module;
}

const SignupFlowStandardOrderModalsWrapper = ({
  openStandardOrder,
  setOpenStandardOrder,
  openVariants,
  setOpenVariants,
  module,
}: Props) => {
  const {
    child,
    schoolId,
    goToNextScreen,
    standard: initialStandard,
    standardWeek,
    standardProducts,
    getCurrentProducts,
    dayProducts,
    dayDateSeconds,
    setSelectedProduct,
    selectProductByDay,
    setStandardWeek,
    setData,
    mealOptions,
    data,
    selectedProduct,
    selectProductOpen,
    setSelectProductOpen,
    flexItems,
    allProducts,
  } = useContainer(SignUpFlowContext);
  const { isBulk, week, mobile } = useContainer(AppContext);

  const { hidePrices } = useMemo(() => {
    return module?.config || {};
  }, [module?.config?.hidePrices]);

  return (
    <>
      <EditStandardOrder
        fn={fn}
        schoolId={schoolId}
        moduleId={module?.id}
        childId={child?.id}
        open={openStandardOrder}
        setOpen={setOpenStandardOrder}
        userId={child?.userId}
        callback={() => goToNextScreen()}
        products={flexItems}
        plainProducts={allProducts}
        standard={initialStandard}
        standardWeek={standardWeek}
        isBulk={isBulk}
        selectedProducts={standardProducts}
        selectStandardOrderOpen={selectProductOpen}
        getCurrentProducts={getCurrentProducts}
        week={week}
        isSignupFlow={true}
      />
      <SelectProductModal
        open={selectProductOpen}
        setOpen={setSelectProductOpen}
        products={dayProducts}
        isBulk={isBulk}
        selectedProducts={standardProducts}
        date={dayDateSeconds}
        week={week}
        isStandard={true}
        mobile={mobile}
        hidePrices={hidePrices}
        setSelectedProduct={setSelectedProduct}
        setOpenVariants={setOpenVariants}
        selectProductByDay={selectProductByDay}
        setStandardWeek={setStandardWeek}
        setVariants={setData}
        standardWeek={standardWeek}
      />
      <VariantsModal
        open={openVariants}
        setOpen={setOpenVariants}
        product={selectedProduct}
        currentDay={selectedProduct?.dates?.[dayDateSeconds]}
        mealOptions={mealOptions}
        module={module}
        data={data}
        setData={setData}
        date={dayDateSeconds}
        selectProductByDay={selectProductByDay}
        setStandardWeek={setStandardWeek}
        fromStandard={true}
      />
    </>
  );
};

export default SignupFlowStandardOrderModalsWrapper;
