import { getWeekSeconds } from "@kanpla/system";
import {
  CombinedOfferItem,
  CustomOrderContent,
  FlexBulkStandard,
  IBaseProducts,
  ISelectProductByDayProps,
  OrderInfo,
  OrderOrder,
  Week,
} from "@kanpla/types";
import { isEmpty } from "lodash";
import moment from "moment";
import React, { Dispatch, SetStateAction, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ActionType } from "..";
import { DrawerOrModal } from "../elements/BottomDrawer/DrawerOrModal";
import { ProductsList } from "./ProductsList";

interface Props {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  products: { [key: string]: CombinedOfferItem[] };
  isBulk: boolean;
  selectedProducts: IBaseProducts;
  date: string;
  week: Week;
  deadlineFormatted?: string;
  isStandard: boolean;
  mobile: boolean;
  hidePrices: boolean;
  setSelectedProduct: Dispatch<SetStateAction<CombinedOfferItem>>;
  setOpenVariants: Dispatch<SetStateAction<boolean>>;
  setDayDateSeconds?: Dispatch<SetStateAction<string>>;
  selectProductByDay: (props: ISelectProductByDayProps) => void;
  onPurchase?: (
    product: CombinedOfferItem,
    data: CustomOrderContent,
    date: string
  ) => Promise<void>;
  submit?: (
    newOrder: OrderOrder,
    info: OrderInfo,
    dateSeconds: string
  ) => Promise<void>;
  setStandardWeek?: Dispatch<SetStateAction<FlexBulkStandard["standard"]>>;
  weekProducts?: IBaseProducts;
  setVariants?: Dispatch<SetStateAction<CustomOrderContent>>;
  standardWeek: FlexBulkStandard["standard"];
}

export const SelectProductModal = ({
  open,
  setOpen,
  products,
  isBulk,
  selectedProducts,
  date,
  week,
  deadlineFormatted,
  isStandard,
  mobile,
  hidePrices,
  setSelectedProduct,
  setOpenVariants,
  setDayDateSeconds,
  selectProductByDay,
  onPurchase,
  submit,
  setStandardWeek,
  weekProducts,
  setVariants,
  standardWeek,
}: Props) => {
  const { t, i18n } = useTranslation([
    "libs",
    "translation",
    "components",
    "flex-bulk",
  ]);

  const hasLanguage = !isEmpty(i18n);

  const weekSeconds = week[0]?.seconds;

  const weekRelative = () => {
    const day = moment.unix(Number(date)).format("dddd");
    const currentSeconds = getWeekSeconds(moment().unix());
    const weeksAhead = (weekSeconds - currentSeconds) / 604800;

    const displayString =
      weeksAhead === 0
        ? `${hasLanguage ? t("translation:this") : "Denne"} ${day}`
        : weeksAhead === 1
        ? `${hasLanguage ? t("translation:next") : "Næste"} ${day}`
        : weeksAhead > 1 && deadlineFormatted;

    return displayString;
  };

  const actions = useMemo<ActionType[]>(() => {
    return [
      {
        label: hasLanguage ? t("translation:confirm") : "Bekræft",
        onClick: () => {
          setOpen(false);
        },
        type: "primary",
      },
    ];
  }, [i18n?.language]);

  const subtitleProp = useMemo(
    () =>
      isStandard
        ? {}
        : {
            subtitle: t("components:order-until", { value: deadlineFormatted }),
          },
    [isStandard, deadlineFormatted]
  );

  return (
    <DrawerOrModal
      title={
        isStandard
          ? hasLanguage
            ? t("flex-bulk:default-selection")
            : "Standardvalg"
          : weekRelative()
      }
      {...subtitleProp}
      open={open}
      setOpen={setOpen}
      actions={actions}
    >
      <ProductsList
        fromStandard={isStandard}
        categories={products}
        isBulk={isBulk}
        selectedProducts={selectedProducts}
        date={date}
        mobile={mobile}
        hidePrices={hidePrices}
        setSelectedProduct={setSelectedProduct}
        setOpenVariants={setOpenVariants}
        setDayDateSeconds={setDayDateSeconds}
        selectProductByDay={selectProductByDay}
        onPurchase={onPurchase}
        submit={submit}
        setStandardWeek={setStandardWeek}
        weekProducts={weekProducts}
        setVariants={setVariants}
        standardWeek={standardWeek}
      />
    </DrawerOrModal>
  );
};
