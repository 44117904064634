import { calculateAmountOfOrderItems } from "@kanpla/system";
import {
  CombinedOfferItem,
  Module,
  OrderConfig,
  OrderOrder,
} from "@kanpla/types";
import { omit } from "lodash";

type Props = {
  module: Module;
  order: OrderOrder;
  offerItems: CombinedOfferItem[];
  optionChoices?: OrderConfig["options"];
};

export const adjustRequiredProduct = ({
  module,
  offerItems,
  order,
  optionChoices,
}: Props) => {
  // 1. Get target product, and skip if not available
  const productId = module?.plugins?.requiredProduct?.productId;
  const product = offerItems.find((i) => i.productId === productId);
  if (!productId || !product) return order;

  // 2. Let's see the situation with the current order
  const orderWithoutRequiredProduct = omit(order, [productId]);
  const requiredProductAmount = order?.[productId]?.amount || 0;
  const orderIsEmpty =
    calculateAmountOfOrderItems(orderWithoutRequiredProduct) === 0;

  // 3. If already correct, return
  const correctlyIncludes =
    !orderIsEmpty &&
    requiredProductAmount === 1 &&
    typeof optionChoices === "undefined";
  const correctlyExcludes = orderIsEmpty && requiredProductAmount === 0;
  if (correctlyIncludes || correctlyExcludes) return order;

  // 4. Otherwise, modify the order to include requiredProduct if the order is not empty
  const withoutRequiredProduct: OrderOrder = {
    ...order,
    [productId]: {
      amount: 0,
      name: product.name,
      price: product.price,
      config: [],
    },
  };

  const withRequiredProduct: OrderOrder = {
    ...order,
    [productId]: {
      amount: 1,
      name: product.name,
      price: product.price,
      config: [
        {
          name: product.name,
          amount: 1,
          options: optionChoices || {},
        },
      ],
    },
  };

  const newOrder: OrderOrder = orderIsEmpty
    ? withoutRequiredProduct
    : withRequiredProduct;

  return newOrder;
};
