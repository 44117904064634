import { fetchMultipleDocuments, getGroup } from "@kanpla/system";
import {
  CombinedOffer,
  FlexOption,
  GroupedCombinedOffer,
  Module,
  Product,
  School,
  Week,
} from "@kanpla/types";
import { useEffect, useState } from "react";
import { getProductOptions } from "../flex/getProductOptions";

interface Props {
  flexModule: Module;
  week: Week;
  school: School;
  modules: Module[];
}

type Return = {
  flexItems: GroupedCombinedOffer;
  allProducts: CombinedOffer;
  mealOptions: FlexOption[];
};

/**
 * This hook substitute the getOffer() function from the loadFrontend implementation.
 */
export const useItems = ({
  flexModule,
  week,
  school,
  modules,
}: Props): Return => {
  const [flexItems, setFlexItems] = useState(null);
  const [allProducts, setAllProducts] = useState([]);
  const [mealOptions, setMealOptions] = useState<FlexOption[]>([]);

  const getOffers = async () => {
    const productIds = Object.keys(flexModule?.products || {});
    const products = await fetchMultipleDocuments<Product>(
      "products",
      productIds
    );

    setAllProducts(products);

    const weekDatesSeconds = getGroup({
      items: week as any,
      entity: "dates",
    });
    const categories = getGroup({ items: products as any, entity: "category" });

    const groupedItems = weekDatesSeconds.reduce((acc, dateSeconds) => {
      const reducedCategories = categories.reduce(
        (acc, category) => ({ ...acc, [category]: [] }),
        {}
      );

      return {
        ...acc,
        [dateSeconds]: reducedCategories,
      };
    }, {});

    Object.entries(groupedItems).forEach(([_, value]) => {
      Object.keys(value).forEach((category) => {
        const filteredItems = products.filter(
          (item) => item.category === category
        );

        value[category] = filteredItems;
      });
    });

    setFlexItems(groupedItems);
  };

  const getMealOptions = async () => {
    const options = await getProductOptions({ school, modules });
    setMealOptions(options);
  };

  useEffect(() => {
    getOffers();
    getMealOptions();
  }, [flexModule, school, JSON.stringify(modules)]);

  return {
    flexItems,
    allProducts,
    mealOptions,
  };
};
